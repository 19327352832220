import { AxiosInstance, AxiosResponse } from 'axios'

import { Endpoints } from './endpoints'

export type RequestHeaderType = {
  'Api-key': string
  Authorization?: string
}

export enum AsyncState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export type EndpointType<T = any, R = any> = (
  data: T,
  fetcher: AxiosInstance,
  headers?: RequestHeaderType,
) => Promise<AxiosResponse<R>>

export type EndpointsType = typeof Endpoints
