import React from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames/bind'

import { Text, TextType } from 'ui/text'
import { ReactComponent as TailArrow } from 'features/pre-launch/assets/TailArrow.svg'

import Personalized from '../../assets/personalized.png'
import Directed from '../../assets/directed.png'
import Tasked from '../../assets/tasked.png'
import { WhatClientsSay } from '../../components/what-clients-say/what-clients-say'
import { CountryManagerResponsibilities } from '../../components/country-manager-responsibilities/country-manager-responsibilities'
import { Layout } from '../../components/layout/layout'

import styles from './country-manager.module.scss'

const cn = classnames.bind(styles)

function CountryManager() {
  const history = useHistory()

  return (
    <Layout>
      <div className={cn('country-manager')}>
        <div className={cn('wrapper')}>
          <div className={cn('who')}>
            <div className={cn('who__description')}>
              <Text type={TextType.HeaderXXLarge} className={cn('who__description-title')}>
                Who is a country manager
              </Text>
              <Text type={TextType.TextXXLarge} className={cn('who__description-sub-title')}>
                A Country Manager works on behalf of a client to represent a company to drive
                business growth in a foreign region.
              </Text>
              <Text type={TextType.TextXLarge}>
                A Country Manager job involves working to manage marketing operations, develop
                business and increase profitability for a company within a targeted region or
                country.
                <br />
                <br />A Country Manager has required skills set, necessary local expertice and deep
                knowledge about a product and business sector.
              </Text>
            </div>
          </div>
          <div className={cn('how')}>
            <Text type={TextType.HeaderLarge}>How a country manager works</Text>
            <div className={cn('how__examples')}>
              <div className={cn('how__example')}>
                <div className={cn('how__example-image')}>
                  <img src={Personalized} alt={'How it starts'} />
                </div>
                <div className={cn('how__example-description')}>
                  <Text type={TextType.TextXLargeBold}>How it starts</Text>
                  <Text type={TextType.TextLarge}>
                    The platform assigns a personal country manager with local expertise and high
                    qualification
                  </Text>
                </div>
              </div>
              <div className={cn('how__example')}>
                <div className={cn('how__example-image')}>
                  <img src={Directed} alt={'Direct Communication'} />
                </div>
                <div className={cn('how__example-description')}>
                  <Text type={TextType.TextXLargeBold}>Direct Communication</Text>
                  <Text type={TextType.TextLarge}>
                    No need to jump between Zoom, Slack or other messengers — all in one window
                  </Text>
                </div>
              </div>
              <div className={cn('how__example')}>
                <div className={cn('how__example-image')}>
                  <img src={Tasked} alt={'Task Management'} />
                </div>
                <div className={cn('how__example-description')}>
                  <Text type={TextType.TextXLargeBold}>Task Management</Text>
                  <Text type={TextType.TextLarge}>
                    All required steps and activities are presented as tasks on kanban board — easy
                    and common to use
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CountryManagerResponsibilities
        header={
          <Text type={TextType.HeaderLarge} className={cn('responsibilities-title')}>
            Country Manager Responsibilities
          </Text>
        }
      />
      <div className={cn('reviews')}>
        <WhatClientsSay headerClassName={cn('reviews__title')} />
      </div>
      <div className={cn('offer')}>
        <Text type={TextType.HeaderLarge} className={cn('offer__title')}>
          We are hiring CMs
        </Text>
        <Text type={TextType.TextLarge} className={cn('offer__description')}>
          To apply, just tell send us email about yourself and your work experience
        </Text>
        <button onClick={() => history.push('/partnership')} className={cn('offer__button')}>
          <Text type={TextType.TextXLargeBold} className={cn('offer__button-text')}>
            Send
          </Text>
          <TailArrow width={32} height={32} className={cn('offer__button-icon')} />
        </button>
      </div>
    </Layout>
  )
}

export { CountryManager }
