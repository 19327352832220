import React, { useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'

import { FinalFormField } from 'ui/final-form-field'
import { Button } from 'ui/button'
import { AuthRequest, AuthResponse } from '../../types'
import { useFormError } from '../../utils/useFormError'
import { useToggle } from 'utils/useToggle'
import { FormError } from 'ui/form-error'
import styles from './auth-form.module.scss'
import { Text, TextType } from 'ui/text'

import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3'

const cn = classNames.bind(styles)
type Props = {
  initialValues?: any
  onSubmit: (values: AuthRequest) => Promise<AuthResponse>
}

function AuthForm(props: Props) {
  const { onSubmit, initialValues = {}, ...rest } = props
  const withFormError = useFormError(onSubmit)
  const [isPasswordVisible, setPasswordVisible] = useToggle()
  const history = useHistory()

  const verifyCallback = (recaptchaToken) => {
    let FinalFormField = document.getElementById('recaptcha')
    if (FinalFormField !== null) {
      FinalFormField.setAttribute('value', recaptchaToken)
      FinalFormField.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }

  useEffect(() => {
    loadReCaptcha('6LeP1K0bAAAAAI1BOBF0PWo8E7OPjxGtgncC-qAN')
  })

  let captcha

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref)
    }
  }

  const resetCaptcha = () => {
    // maybe set it till after is submitted
    console.log(1)
    captcha.reset()
  }

  return (
    <div {...rest} className={cn('container')}>
      {/* <GoogleReCaptchaProvider reCaptchaKey="6LeP1K0bAAAAAI1BOBF0PWo8E7OPjxGtgncC-qAN"> */}
      <Form
        initialValues={initialValues}
        onSubmit={withFormError.handlers.submit}
        render={({ hasSubmitErrors, handleSubmit }) => (
          <form className={cn('auth__form')} onSubmit={handleSubmit} id={'form__submit'}>
            {withFormError.message && (
              <FormError message={withFormError.message} close={withFormError.handlers.close} />
            )}
            <FinalFormField
              name={'email'}
              type={'email'}
              className={cn('input', '_email')}
              label="Email"
              placeholder="john@doe.com"
            />
            <div className={cn('password-wrapper')}>
              <FinalFormField
                type={isPasswordVisible ? 'text' : 'password'}
                name={'password'}
                className={cn('input', '_password')}
                label="Password"
                placeholder="********"
              />

              <div
                onClick={setPasswordVisible}
                className={cn('password-eyes', { _visible: isPasswordVisible })}
              ></div>
            </div>
            <ReCaptcha
              sitekey="6LeP1K0bAAAAAI1BOBF0PWo8E7OPjxGtgncC-qAN"
              action="register"
              verifyCallback={verifyCallback}
            />
            <FinalFormField
              type={'text'}
              name={'recaptcha'}
              id={'recaptcha'}
              className={cn('recaptcha')}
            />
            <div className={cn('button__wrapper')}>
              <Button disabled={withFormError.submitting} className={cn('button')} type="submit">
                Continue
              </Button>
            </div>
            {/* <Text
              onClick={() => history.push('/registration/idendity')}
              className={cn('link')}
              type={TextType.TextSmall}
            >
              Register
            </Text> */}
          </form>
        )}
      />
      {/* </GoogleReCaptchaProvider> */}
    </div>
  )
}

export { AuthForm }
