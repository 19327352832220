import React from 'react'

import {
  AuthRequest,
  AuthResponse,
  RegistrationSteps,
  SaveCompanyRequest,
  SaveCompanyResponse,
} from '../../types'

import { Checkout } from 'features/checkout/pages/checkout'
import { PaymentError } from 'features/checkout/components/payment-error'

import { Identify } from './identify'
import { Company } from './company'
import { Countries } from './countries'

type Props = {
  stepId: RegistrationSteps
  register: (values: AuthRequest) => Promise<AuthResponse>
  saveCompany: (values: SaveCompanyRequest) => Promise<SaveCompanyResponse>
  availableCountries: any
}

function RegisterForm(props: Props) {
  const { stepId, register, saveCompany, availableCountries } = props

  switch (stepId) {
    case RegistrationSteps.COMPANY:
      return <Company onSubmit={saveCompany} />
    case RegistrationSteps.COUNTRIES:
      return (
        <React.Suspense fallback={() => 'Loading...'}>
          <Countries availableCountries={availableCountries} />
        </React.Suspense>
      )
    case RegistrationSteps.CHECKOUT:
      return <Checkout />
    case RegistrationSteps.PAYMENT_ERROR:
      return <PaymentError />
    case RegistrationSteps.IDENTIFY:
    default:
      return <Identify onSubmit={register} />
  }
}

export { RegisterForm }
