import { flow, observable, action, computed, makeAutoObservable } from 'mobx'
import Pusher from 'pusher-js'

import { RootStore } from 'stores/root-store'
import { api, AsyncState } from 'services/api'
import { makeResource, Resource } from 'utils/make-resource'

export class NotificationsStore {
  @observable
  entities: Array<any> = []

  pusher

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this)
    this.loadAll.bind(this)
    this.pusher = new Pusher('0d8c65de346b378c8006', {
      authEndpoint: 'https://ws.ohhimarket.com/broadcasting/auth',
      cluster: 'us2',
      auth: {
        headers: {
          Authorization: api.authHeaders.Authorization,
        },
      },
    })
  }

  init(id) {
    this.loadAll()
    const channel = this.pusher.subscribe(`private-Notification.${id}`)

    channel.bind('NotificationEvent', (evt) => {
      this.add(evt)
    })
  }

  @action
  add(notification) {
    this.entities.push(notification)
  }

  @computed
  get count() {
    return this.entities.length
  }

  @computed
  get items() {
    const items = [...this.entities]
    items.reverse()
    return items
  }

  loadAll = flow(function* (this) {
    const raw: any = yield api.call('GET_ALL_NOTIFICATIONS')

    if (raw.success) {
      this.entities = [...raw.data]
      return raw.data
    }

    throw new Error(raw.message)
  })

  async read(id) {
    await api.call('READ_NOTIFICATION', { id })
  }
}
