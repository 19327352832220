export enum TextType {
  HeaderXXLarge = 'header-xx-large', // 72
  HeaderXLarge = 'header-x-large', // 54
  HeaderLarge = 'header-large', // 44
  HeaderMedium = 'header-medium', // 36
  HeaderSmall = 'header-small', // 24
  HeaderXSmall = 'header-x-small', // 21
  TextXXLarge = 'text-xx-large', // 24
  TextXLarge = 'text-x-large', // 21
  TextXLargeBold = 'text-x-large-bold', // 21
  TextXLargeMedium = 'text-x-large-medium', // 21
  TextLargeBold = 'text-large-bold', // 17.5
  TextLarge = 'text-large', // 17.5
  TextMediumBold = 'text-medium-bold', // 15
  TextMedium = 'text-medium', // 15
  TextSmall = 'text-small', // 12
  TextIcon = 'text-icon',
}
