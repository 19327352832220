import React, { Suspense, useContext } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames/bind'
import { useVisibleState } from 'utils/useVisibleState'
import { AppContext } from 'app-context'
import { Content } from '../../components/content'
import { Sidebar } from '../../components/sidebar'
import { PasswordModal } from '../../components/password-modal'
import { PaymentModal } from '../../components/payment-modal'
import { Spinner } from 'ui/spinner'
import styles from './settings.module.scss'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from 'common/constants'
const cn = classNames.bind(styles)
type Props = React.HTMLProps<HTMLDivElement>

function Settings(props: Props) {
  const { visible, open, close } = useVisibleState()
  const { visible: visiblePayment, open: openPayment, close: closePayment } = useVisibleState()

  const { settings } = useContext(AppContext)

  const onPasswordSubmit = React.useCallback(
    async (evt) => {
      const result = await settings.updatePassword(evt)

      return result
    },
    [settings.updatePassword],
  )

  const onUpdatePaymentMethod = React.useCallback(
    async (id) => {
      const result = await settings.updatePaymentMethod({
        paymentMethodId: id,
      })
    },
    [settings.updatePaymentMethod],
  )

  const onCreatePaymentMethod = React.useCallback(
    async (payment_method) => {
      const result = await settings.createPaymentMethod({
        payment_method,
      })
      closePayment()
    },
    [settings.createPaymentMethod],
  )

  const onDeletePaymentMethod = React.useCallback(
    async (id) => {
      const result = await settings.deletePaymentMethod({
        paymentMethodId: id,
      })
    },
    [settings.deletePaymentMethod],
  )

  return (
    <Suspense fallback={<Spinner type={'fullscreen'} />}>
      <>
        <div className={cn('content')}>
          <Content
            onUpdatePaymentMethod={onUpdatePaymentMethod}
            onDeletePaymentMethod={onDeletePaymentMethod}
            handleOpenPaymentModal={openPayment}
          />
        </div>
        <div className={cn('sidebar')}>
          <Sidebar handleOpenPasswordModal={open} />
        </div>
        <PasswordModal
          onSubmit={onPasswordSubmit}
          visible={visible}
          handleClosePasswordModal={close}
        />
        <PaymentModal
          onCreatePaymentMethod={onCreatePaymentMethod}
          visible={visiblePayment}
          handleClosePaymentModal={closePayment}
        />
      </>
    </Suspense>
  )
}

const ObservedSettings = observer(Settings)

export { ObservedSettings as Settings }
