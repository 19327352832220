import { AUTH_API } from 'features/auth'
import { CHECKOUT_API } from 'features/checkout'
import { PROJECT_API } from 'features/tasks'
import { CLIENTS_API } from 'features/clients'
import { SETTINGS_API } from 'features/settings'
import { STATIC_PAGES_API } from 'features/static-pages'
import { NOTIFICATIONS_API } from 'features/notifications'
import { ZOOM_API } from 'features/zoom-button'
import { RESEARCH_API } from 'features/research'

export const Endpoints = {
  ...AUTH_API,
  ...CHECKOUT_API,
  ...PROJECT_API,
  ...CLIENTS_API,
  ...STATIC_PAGES_API,
  ...NOTIFICATIONS_API,
  ...SETTINGS_API,
  ...ZOOM_API,
  ...RESEARCH_API,
}
