import { useLayoutEffect, useState } from 'react'

const BREAKPOINTS = {
  '(max-width: 1280px)': 'landscapeTablet' as const,
  '(max-width: 1024px)': 'tablet' as const,
  '(max-width: 768px)': 'mobileLandscape' as const,
  '(max-width: 568px)': 'mobile' as const,
}

type ValuesUnion<T extends Record<string, string>> = T extends { [k: string]: infer A } ? A : string
type Matches<T extends string> = { [k in T]: boolean }

export type UseBreakpoints<T extends Record<string, string>> = Matches<ValuesUnion<T>>

function useBreakpoints<T extends Record<string, string>>(breakPoints: T): UseBreakpoints<T> {
  const [state, setState] = useState(() => {
    if (typeof window === 'undefined') {
      return {
        mq: [] as MediaQueryList[],
        matches: {} as Matches<ValuesUnion<T>>,
      }
    }

    return Object.entries(breakPoints).reduce(
      (acc, [mq, key]) => {
        const mqObj = window.matchMedia(mq)
        acc.mq.push(mqObj)
        acc.matches[key] = mqObj.matches
        return acc
      },
      {
        mq: [] as MediaQueryList[],
        matches: {} as Matches<ValuesUnion<T>>,
      },
    )
  })

  useLayoutEffect(() => {
    const handler = ({ matches, media }): void => {
      setState((prev) => ({
        ...prev,
        matches: { ...prev.matches, [breakPoints[media]]: matches },
      }))
    }
    state.mq.forEach((mq) => {
      if ('addEventListener' in (mq as Partial<MediaQueryList>)) {
        mq.addEventListener('change', handler)
      } else {
        mq.addListener(handler)
      }
    })

    return (): void => {
      state.mq.forEach((mq) => {
        if ('removeEventListener' in (mq as Partial<MediaQueryList>)) {
          mq.removeEventListener('change', handler)
        } else {
          mq.removeListener(handler)
        }
      })
    }
  }, [breakPoints, state.mq])

  return state.matches
}

export { BREAKPOINTS, useBreakpoints }
