import { RootStore } from 'stores/root-store'
import { api } from 'services/api'

// import { ErrorLoginResponse, LoginRequest, SuccessLoginResponse } from '../types'

export class CheckoutStore {
  constructor(private rootStore: RootStore) {}

  async getCustomer(): Promise<any | any> {
    try {
      const result: any = await api.call('GET_CUSTOMER')

      return result
    } catch (err: any) {
      return err.response.data.message
    }
  }
  async subscribe(payload): Promise<any | any> {
    try {
      const result: any = await api.call('SUBSCRIBE', payload)
      return result
    } catch (err: any) {
      return err.response.data
    }
  }
  async checkPromocode(payload): Promise<any | any> {
    try {
      const result: any = await api.call('CHECKPROMOCODE', payload)
      return result
    } catch (err: any) {
      return err.response.data
    }
  }
}
