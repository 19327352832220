import { useContext, useEffect, useCallback } from 'react'
import classNames from 'classnames/bind'
import pluralize from 'pluralize'
import isUndefined from 'lodash/isUndefined'
import { Text, TextType } from 'ui/text'
import { AppContext } from 'app-context'
import { ZoomButton } from 'features/zoom-button'
import { Access } from 'common/types'
import { useHistory, useParams } from 'react-router-dom'

import { Statuses } from '../../types'
import { TaskClock } from '../task-clock'

import styles from './task-list.module.scss'

type Props = {
  id?: number
  src?: string
  created?: string
  filename: string
  deleteAttachment?: any
  user?: any
}

const cn = classNames.bind(styles)

function List({
  items,
  handleTaskClick,
  emptyText = 'Here will be tasks for country manager',
}: any) {
  return (
    <>
      {items.length ? (
        items.map((task) => (
          <div onClick={() => handleTaskClick(task.id)} key={task.id} className={cn('task-block')}>
            <div className={cn('task-status')}>{Statuses[task.status]}</div>
            <div className={cn('task-title')}>{task.title}</div>
            <div className={cn('task-description')}>{task.description}</div>
          </div>
        ))
      ) : (
        <div className={cn('empty')}>
          <Text type={TextType.TextLarge}>{emptyText}</Text>
        </div>
      )}
    </>
  )
}

function TaskList({ aviableTasks, currentProjectId, currentUser, projects }: any) {
  const tasks = aviableTasks.read(currentProjectId)

  const createZoomMeeting = useCallback(() => {
    return currentUser.createZoomMeeting(currentProjectId)
  }, [currentUser.createZoomMeeting, currentProjectId])

  const history = useHistory()

  const { companyId } = useParams()

  const handleTaskClick = (id) => {
    if (currentUser.current.role === Access.CUSTOMERDASHBOARD) {
      history.push(`/tasks?projectId=${currentProjectId}&taskId=${id}`)
    }
    if (currentUser.current.role === Access.COUNTRY_MANAGER) {
      history.push(`/clients/${companyId}?projectId=${currentProjectId}&taskId=${id}`)
    }
  }
  const projectDetail = projects.find((project) => project.id === Number(currentProjectId))

  console.info(projectDetail)
  return (
    <>
      {currentUser.current.role === Access.COUNTRY_MANAGER ? (
        <ZoomButton role={currentUser.current.role} onClick={createZoomMeeting} />
      ) : (
        <Text className={cn('content-title')} type={TextType.HeaderXLarge}>
          You have <br />
          <span className={cn('task_count')}>
            {tasks.my_tasks_count || '0'} {pluralize('task', tasks.my_tasks_count || 0)}{' '}
          </span>
          today
        </Text>
      )}

      <div className={cn('clock-content')}>
        <div className={cn('clock-title')}>
          <img className={cn('flag')} width="22" height="16" src={projectDetail.country.flag} />
          <Text type={TextType.TextXLargeBold}>{projectDetail.country.en_name}</Text>
        </div>

        <div className={cn('clock-blocks')}>
          <div className={cn('clock-block')}>
            <TaskClock currentProject={projectDetail} />
          </div>
          <div className={cn('clock-block', '_holidays')}>
            <Text type={TextType.TextMediumBold} className={cn('holiday-title')}>
              Nearest Holidays
            </Text>

            <div className={cn('holiday-block')}>
              <Text type={TextType.TextSmall} className={cn('holiday-date')}>
                {projectDetail.country.country_holiday_nearest.date}{' '}
                <span className={cn('holiday-week')}>
                  {projectDetail.country.country_holiday_nearest.day_week}
                </span>
              </Text>
              <Text type={TextType.TextMediumBold} className={cn('holiday-name')}>
                {projectDetail.country.country_holiday_nearest.name}
              </Text>
            </div>
          </div>
        </div>
      </div>

      <div className={cn('tasks')}>
        <div className={cn('task')}>
          <div className={cn('task-name')}>My tasks</div>
          <List
            items={tasks.my_tasks}
            handleTaskClick={handleTaskClick}
            emptyText={'Here will be your tasks'}
          />
        </div>
        <div className={cn('task')}>
          <div className={cn('task-name')}>
            <span className={cn('task-avatar')}>
              <img
                src={tasks.companion.avatar}
                alt="avatar"
                width="24"
                height="24"
                className={cn('manager-avatar')}
              />{' '}
            </span>
            <div>Country manager tasks</div>
          </div>
          <List items={tasks.companion_tasks} handleTaskClick={handleTaskClick} />
        </div>
      </div>
    </>
  )
}

export { TaskList }
