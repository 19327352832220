import axios from 'axios'

import { api } from 'services/api'
import { makeResource } from 'utils/make-resource'

async function getChat(projectId) {
  return axios
    .post(
      'https://chat.ohhimarket.com/chat',
      { project_id: projectId },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          ...api.authHeaders,
        },
        responseType: 'blob',
      },
    )
    .then((response) => {
      return URL.createObjectURL(response.data)
    })
}

const chatResource = makeResource(getChat)

export { chatResource }
