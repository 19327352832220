import { useContext, Suspense } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContext } from 'app-context'
import { Spinner } from 'ui/spinner'
import { Access } from 'common/types'
import { api } from 'services/api'

function Me() {
  const history = useHistory()
  const ctx = useContext(AppContext)
  ctx.bootstrapResources.criticalAppDependencies.read()

  const user = ctx.currentUser.current
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    api.forgot()
    history.push('/mobile-error')
  } else {
    if (user.role === Access.COUNTRY_MANAGER) {
      // history.push('/clients')
      history.push('/research')
    }
    if (user.role === Access.CUSTOMER) {
      // history.push('/tasks')
      history.push('/research')
    }
  }
  return null
}

function Wrapper() {
  return (
    <Suspense fallback={<Spinner type={'fullscreen'} />}>
      <Me />
    </Suspense>
  )
}

export { Wrapper as Me }
