import React from 'react'
import classNames from 'classnames/bind'

import { Text, TextType } from 'ui/text'

import { ReactComponent as DefaultHeader } from '../../assets/remote_title.svg'

import styles from './country-manager-responsibilities.module.scss'
const cn = classNames.bind(styles)

type Props = {
  header?: any
}

export function CountryManagerResponsibilities({ header }: Props) {
  return (
    <div className={cn('wrapper')}>
      <div className={cn('job-checkboxes')}>
        <div className={cn('job-checkboxes__header')}>
          {header || <DefaultHeader className={cn('job-checkboxes__header-default')} />}
        </div>
        <div className={cn('job-checkboxes__content')}>
          <div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Audit
                </Text>
                <Text className={cn('job-description')} type={TextType.TextMedium}>
                  Web, apps, campaigns
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Business Development
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Campaigns Arrangement
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Content Management
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Copywriting
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Design
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Fund Raising
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  HR services
                </Text>
              </div>
            </div>
          </div>
          <div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Leads Generating
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Legal Consultation
                </Text>
                <Text className={cn('job-description')} type={TextType.TextMedium}>
                  Establishment, payments, operations
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Marketing Strategy
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Market Research
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Media Publications
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  PR
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Promotion
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Sales Funnel Optimisation
                </Text>
              </div>
            </div>
          </div>
          <div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Search Engine Advertising
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Search Engine Optimisation
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Social Media Marketing
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Social Media Optimisation
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Team Leading
                </Text>
              </div>
            </div>
            <div className={cn('job-checkbox')}>
              <div className={cn('job-checkmark')}></div>
              <div className={cn('job-text')}>
                <Text type={TextType.TextXLargeMedium} className={cn('job-title')}>
                  Translation and Localization
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
