import { useLocation, useHistory } from 'react-router-dom'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import classnames from 'classnames/bind'
import common_styles from '../containers/checkout/checkout.module.scss'
import styles from './payment-error.module.scss'
const cn = classnames.bind({ ...common_styles, ...styles })

const PaymentError = () => {
  const location = useLocation()
  const history = useHistory()

  return (
    <>
      <div className={cn('auth__logo')} onClick={() => window.open('https://himrkt.com')} />
      <div className={cn('wrapper')}>
        <div className={cn('content')}>
          <div className={cn('block')}>
            <Text type={TextType.HeaderLarge}>Payment error</Text>
            <Text className={cn('description')} type={TextType.TextLarge}>
              {location.state.message}
            </Text>
            <Button type="button" onClick={() => history.push('/registration/countries')}>
              Try again
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export { PaymentError }
