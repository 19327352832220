import { AxiosInstance } from 'axios'

import { EndpointType } from 'services/api/types'

import {
  AuthRequest,
  AuthResponse,
  AvailableCountriesResponse,
  SaveCompanyRequest,
  SaveCompanyResponse,
} from './types'

enum URL {
  REFRESH_TOKEN = 'api/auth/refresh-token',
  REGISTER = 'api/auth/register',
  LOGIN = 'api/auth/login',
  GET_USER = 'api/cabinet/user',
  SAVE_COMPANY = 'api/cabinet/companies',
  AVAILABLE_COUNTRIES = 'content/dictionaries/countries/research/prices',
}

const REFRESH_TOKEN: EndpointType = (data: any, fetcher, headers) =>
  fetcher.request({
    method: 'post',
    url: URL.REFRESH_TOKEN,
    data,
    headers,
  })

const REGISTER: EndpointType<AuthRequest, AuthResponse> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'post',
    url: URL.REGISTER,
    data,
    headers,
  })

const LOGIN: EndpointType<AuthRequest, AuthResponse> = (data, fetcher, headers) => {
  return fetcher.request({
    method: 'post',
    url: URL.LOGIN,
    data,
    headers: {
      'Api-key': headers?.['Api-key'],
    },
  })
}

// todo test method, will moved after tests
const GET_USER: EndpointType = (data: any, fetcher: AxiosInstance, headers) => {
  return fetcher.request({
    method: 'get',
    url: URL.GET_USER,
    headers,
  })
}

const SAVE_COMPANY: EndpointType<SaveCompanyRequest, SaveCompanyResponse> = (
  data,
  fetcher: AxiosInstance,
  headers,
) => {
  return fetcher.request({
    method: 'post',
    url: URL.SAVE_COMPANY,
    data,
    headers,
  })
}

const AVAILABLE_COUNTRIES: EndpointType<any, AvailableCountriesResponse> = (
  data: any,
  fetcher: AxiosInstance,
  headers,
) => {
  return fetcher.request({
    method: 'get',
    url: URL.AVAILABLE_COUNTRIES,
    headers,
  })
}

export const AUTH_API = {
  REFRESH_TOKEN,
  REGISTER,
  LOGIN,
  GET_USER,
  SAVE_COMPANY,
  AVAILABLE_COUNTRIES,
}
