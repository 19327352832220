import React, { useState, useContext, useCallback } from 'react'
import classNames from 'classnames/bind'
import { Modal, ModalProps } from 'react-responsive-modal'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { useValidationSchema } from 'utils/useValidationSchema'
import { FinalFormField } from 'ui/final-form-field'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import { FormError } from 'ui/form-error'
import { useFormError } from 'features/auth/utils/useFormError'
import { editPasswordSchema } from '../../schemas'
import styles from './password-modal.module.scss'

const cn = classNames.bind(styles)
type Props = React.HTMLProps<HTMLDivElement>

function PasswordModal(props: any) {
  const { className, handleClosePasswordModal, visible, onSubmit } = props
  const withFormError = useFormError(onSubmit)

  const validate = useValidationSchema(editPasswordSchema)

  return (
    <Modal
      open={visible}
      onClose={handleClosePasswordModal}
      center
      showCloseIcon={false}
      classNames={{
        modal: cn('modal'),
      }}
    >
      {!withFormError.succceeded && (
        <>
          <Text className={cn('modal-title')} type={TextType.HeaderMedium}>
            Change Password
          </Text>
          <Form
            onSubmit={withFormError.handlers.submit}
            validate={validate}
            render={({ handleSubmit, form, submitting, errors, touched }) => (
              <form onSubmit={handleSubmit} className={cn('form')}>
                {withFormError.message && (
                  <FormError message={withFormError.message} close={withFormError.handlers.close} />
                )}
                {console.info(errors)}
                <div className={cn('modal-field', '_current')}>
                  <FinalFormField
                    name={'old_password'}
                    error={touched && touched.old_password && errors && errors.old_password}
                    type={'password'}
                    placeholder={'Current Password'}
                    required
                  />
                </div>
                <div className={cn('modal-field')}>
                  <FinalFormField
                    name={'new_password'}
                    error={touched && touched.new_password && errors && errors.new_password}
                    type={'password'}
                    placeholder={'New password (6-60 characters)'}
                    required
                  />
                </div>
                <div className={cn('modal-field')}>
                  <FinalFormField
                    name={'password_confirmation'}
                    error={
                      touched &&
                      touched.password_confirmation &&
                      errors &&
                      errors.password_confirmation
                    }
                    type={'password'}
                    placeholder={'Confirm new password'}
                    required
                  />
                </div>

                <div className={cn('modal-buttons')}>
                  <Button
                    type="button"
                    onClick={handleClosePasswordModal}
                    className={cn('form__button', '_gray')}
                  >
                    <Text type={TextType.TextLargeBold}>Cancel</Text>
                  </Button>
                  <Button
                    type={'submit'}
                    disabled={withFormError.submitting}
                    className={cn('form__button')}
                  >
                    <Text type={TextType.TextLargeBold}>Save</Text>
                  </Button>
                </div>
              </form>
            )}
          />
        </>
      )}
      {withFormError.succceeded && (
        <>
          <Text className={cn('modal-title')} type={TextType.HeaderMedium}>
            Change Password
          </Text>
          <Text className={cn('modal-desc')} type={TextType.TextMedium}>
            Your password has been reset.
          </Text>
          <Button type={'button'} onClick={handleClosePasswordModal} className={cn('form__button')}>
            <Text type={TextType.TextLargeBold}>Ok</Text>
          </Button>
        </>
      )}
    </Modal>
  )
}

export { PasswordModal }
