import { useContext, useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { AppContext } from 'app-context'
import { usePrevious } from 'utils/usePrevious'
import { useVisibleState } from 'utils/useVisibleState'
import isEqual from 'lodash/isEqual'
import { parse } from 'query-string'

import { TasksComponent } from 'features/tasks/components/tasks-component'
type Props = React.HTMLProps<HTMLDivElement>

const ClientDetail = (props: Props) => {
  const { companyId } = useParams()
  const { currentUser, project } = useContext(AppContext)
  const { visible, open, close } = useVisibleState()

  const projects = project.projects.read({
    company_id: companyId,
  })

  const history = useHistory()
  useEffect(() => {
    if (project.currentProjectId) {
      history.push(`/clients/${companyId}?projectId=${project.currentProjectId}`)
    }
    if (project.currentTaskId) {
      history.push(
        `/clients/${companyId}?projectId=${project.currentProjectId}&taskId=${project.currentTaskId}`,
      )
    }
  }, [])

  const { search } = useLocation()
  const prevSearch = usePrevious(search)

  const queries = parse(search)
  const prevQueries = parse(prevSearch)

  if (!isEqual(queries.projectId, prevQueries.projectId)) {
    project.setCurrentProject(queries.projectId)
    project.tasksResource.reset()
  }

  if (!isEqual(queries.taskId, prevQueries.taskId)) {
    project.setCurrentTask(queries.taskId)
    project.taskResource.reset()
  }

  return (
    <TasksComponent
      aviableTasks={project.tasksResource}
      visibleCardDetail={visible}
      handleOpenCardDetail={open}
      projects={projects}
      currentProjectId={project.currentProjectId}
      currentUser={currentUser}
      setCurrentTask={project.setCurrentTask}
    />
  )
}

const observedClientDetail = observer(ClientDetail)
export { observedClientDetail as ClientDetail }
