import React from 'react'
import classNames from 'classnames/bind'

import { FormFieldProps } from './types'
import styles from './form-field.module.scss'

const cn = classNames.bind(styles)

function FormField(props: FormFieldProps & { error?: any }) {
  const {
    className,
    label,
    id,
    name,
    placeholder,
    type,
    value,
    disabled,
    labelClassName,
    required,
    onChange,
    error,
  } = props

  return (
    <>
      {label && (
        <label className={cn('label', labelClassName)} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={cn('input', className, { _error: error })}
        required={required}
      />
      {error && <span className={cn('error-text')}>{error}</span>}
    </>
  )
}

export { FormField }
