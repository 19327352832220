import React, { useCallback } from 'react'
import classnames from 'classnames/bind'
import { Field, Form } from 'react-final-form'
import { Link, useHistory } from 'react-router-dom'

import { Text, TextType } from 'ui/text'
import { withBreakpointsSlice } from 'ui/breakpoints-provider'
import { Layout } from 'components/layout'
import { api } from 'services/api'

import { WhatClientsSay } from '../../components/what-clients-say/what-clients-say'
import MetaSrc from '../../assets/meta_partner.png'
import MetaSrc2x from '../../assets/meta_partner@2x.png'
import GoogleSrc from '../../assets/google_partner.svg'
import StripeSrc from '../../assets/stripe.svg'
import { ReactComponent as ArrowIcon } from '../../assets/arrow-icon.svg'
import { WhatInside } from '../../components/what-inside'

import styles from './main.module.scss'

const cn = classnames.bind(styles)

const Main = (): JSX.Element => {
  const history = useHistory()

  window.location.href = 'https://himrkt.com'
  const onEmailSubmit = useCallback(
    (evt) => {
      history.push('registration/identify', {
        email: evt.email,
      })
    },
    [history],
  )

  return <></>
}

const MainWithBreakpoints = withBreakpointsSlice(Main, ({ mobile }) => ({ mobile }))

export { MainWithBreakpoints as Main }
