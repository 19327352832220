import React from 'react'
import { observer } from 'mobx-react'

import { AppContext } from 'app-context'
import { Spinner } from 'ui/spinner'

import { Chat } from '../components/chat/chat'

function ChatContainer(props) {
  const { projectId } = props
  const ctx = React.useContext(AppContext)

  return (
    <React.Suspense fallback={<Spinner />}>
      <Chat projectId={projectId} />
    </React.Suspense>
  )
}

const ObservedChatContainer = observer(ChatContainer)
export { ObservedChatContainer as Chat }
