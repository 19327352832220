import { useState, useEffect } from 'react'
import classNames from 'classnames/bind'
import Clock from 'react-clock'
import 'react-clock/dist/Clock.css'

import { Text, TextType } from 'ui/text'
import { Attachment } from '../attachment/attachment'
import styles from '../task-list/task-list.module.scss'

type Props = {
  items: Array<any>
}

const cn = classNames.bind(styles)

const getTimeZonedDate = (date: Date, timeZone) => {
  return new Date(date.toLocaleString('en-US', { timeZone }))
}

function TaskClock({ currentProject }: any) {
  const [value, setValue] = useState(getTimeZonedDate(new Date(), currentProject.country.timezone))

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date()
      setValue(getTimeZonedDate(date, currentProject.country.timezone))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <Text type={TextType.TextMediumBold}>Local Time</Text>
      <div className={cn('clocks')}>
        <div className={cn('clock', '_analog')}>
          <Clock value={value} />
        </div>
        <div className={cn('clock')}>
          <Text type={TextType.TextLarge}>
            {new Intl.DateTimeFormat('default', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric',
            }).format(value)}
          </Text>
        </div>
      </div>
    </>
  )
}

export { TaskClock }
