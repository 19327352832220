import { AxiosInstance } from 'axios'

import { EndpointType } from 'services/api/types'

const URL = {
  GET_RESEARCH_REPORTS: (id) => `api/cabinet/research/reports?country_id=${id}`,
  GET_LOCAL_EXPERTS: (id) => `api/cabinet/research/local-experts?country_id=${id}`,
  GET_COUNTRY_FACTS: (id) => `api/cabinet/research/facts?country_id=${id}`,
  GET_TOP_DIGITAL_COMPANIES: (id) => `api/cabinet/research/top-dm-companies?country_id=${id}`,
  GET_CHART_MENU: (id) => `api/cabinet/research/indicator/graphNames?country_id=${id}`,
  GET_CHART_GRAPHS: (data) =>
    `api/cabinet/research/indicator/graphs?country_id=${data.id}&type=${data.type}`,
}

const GET_RESEARCH_REPORTS: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'get',
    url: URL.GET_RESEARCH_REPORTS(data.id),
    data,
    headers,
  })

const GET_LOCAL_EXPERTS: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'get',
    url: URL.GET_LOCAL_EXPERTS(data.id),
    data,
    headers,
  })

const GET_COUNTRY_FACTS: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'get',
    url: URL.GET_COUNTRY_FACTS(data.id),
    data,
    headers,
  })

const GET_TOP_DIGITAL_COMPANIES: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'get',
    url: URL.GET_TOP_DIGITAL_COMPANIES(data.id),
    data,
    headers,
  })
const GET_CHART_MENU: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'get',
    url: URL.GET_CHART_MENU(data.id),
    data,
    headers,
  })
const GET_CHART_GRAPHS: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'get',
    url: URL.GET_CHART_GRAPHS(data),
    data,
    headers,
  })

export const RESEARCH_API = {
  GET_RESEARCH_REPORTS,
  GET_LOCAL_EXPERTS,
  GET_COUNTRY_FACTS,
  GET_TOP_DIGITAL_COMPANIES,
  GET_CHART_MENU,
  GET_CHART_GRAPHS,
}
