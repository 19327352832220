import React, { useContext, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { observer } from 'mobx-react'
import { AuthorizedLayout } from 'features/authorized-layout'
import { AuthRoute } from 'features/router/components/auth-route'
import { Spinner } from 'ui/spinner'

type RoutesProps = {
  routes: Array<any>
}

const RouteWithSubRoutes = ({ type, ...route }) => {
  switch (type) {
    case 'auth': {
      return <AuthRoute {...route} />
    }

    default: {
      return (
        <Route
          exact={route.exact}
          path={route.path}
          render={(props) => <route.component {...props} routes={route.routes} />}
        />
      )
    }
  }
}

export const RenderRoutes = ({ routes }: RoutesProps) => {
  return (
    <Switch>
      {routes.map((route) => {
        return <RouteWithSubRoutes key={route.key} {...route} />
      })}
      <Route component={() => <div>Not Found</div>} />
    </Switch>
  )
}
const RenderMainRoutes = ({ routes }) => {
  return (
    <Suspense fallback={<Spinner type={'fullscreen'} />}>
      <AuthorizedLayout routes={routes} />
    </Suspense>
  )
}

const ObservedRenderMainRoutes = observer(RenderMainRoutes)

export { ObservedRenderMainRoutes as RenderMainRoutes }
