import classnames from 'classnames/bind'
import { Footer } from 'features/research/components/footer'

import { Header } from '../header/header'

import styles from './layout.module.scss'

const cn = classnames.bind(styles)
export function Layout(props): JSX.Element {
  const { children } = props
  return (
    <div className={cn('wrapper')}>
      <Header />
      {children}
      <Footer />
    </div>
  )
}
