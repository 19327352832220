import React, { useState, useContext, useEffect } from 'react'
import classNames from 'classnames/bind'
import styled from 'styled-components'
import { AppContext } from 'app-context'
import { Modal, ModalProps } from 'react-responsive-modal'

import { Countries } from './countries'

import styles from './dobuymodal.module.scss'
const cn = classNames.bind(styles)

function DoBuyModal(props: any) {
  const store = useContext(AppContext)
  const availableCountries: any = store.auth.availableCountries

  const { visible, handleCloseDoBuyModal, onCreateSubscription, error, setError } = props

  return (
    <Modal
      open={visible}
      onClose={handleCloseDoBuyModal}
      center
      showCloseIcon={false}
      classNames={{
        modal: cn('modal'),
      }}
    >
      <React.Suspense fallback={() => 'Loading...'}>
        <Countries
          availableCountries={availableCountries}
          onCreateSubscription={onCreateSubscription}
          error={error}
          setError={setError}
        />
      </React.Suspense>
    </Modal>
  )
}

export { DoBuyModal }
