import React from 'react'
import { observer } from 'mobx-react'

import { RenderRoutes } from 'components/routes'
import { AppContext } from 'app-context'

import { Layout } from '../components/layout'

function AuthorizedLayout({ routes }) {
  const ctx = React.useContext(AppContext)

  ctx.bootstrapResources.criticalAppDependencies.read()

  const user = ctx.currentUser.current

  const roles = user.roles.map((role) => role.id)

  const accessRoutes = routes.filter((route) => {
    return roles.some((role) => role === route.access)
  })
  const allRoutes = [...accessRoutes]

  return (
    <Layout>
      <RenderRoutes routes={allRoutes} />
    </Layout>
  )
}

const observedAuthLayout = observer(AuthorizedLayout)
export { observedAuthLayout as AuthorizedLayout }
