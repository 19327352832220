import React from 'react'
import classNames from 'classnames/bind'

import { Spinner } from 'ui/spinner'

import styles from './attachment.module.scss'

type Props = {
  id?: number
  src?: string
  created?: string
  filename: string
  deleteAttachment?: any
}

const cn = classNames.bind(styles)

function Attachment({ id, deleteAttachment, src, filename, created }: Props) {
  const handleDelete = React.useCallback(() => {
    deleteAttachment(id)
  }, [id])

  return (
    <div className={cn('attachment')}>
      {Boolean(id) ? (
        <img width="120" height="80" src={src} className={cn('attachment-image')} />
      ) : (
        <div className={cn('attachment-loading')}>
          <Spinner width={'80px'} height={'80px'} />
        </div>
      )}

      <div className={cn('attachment-descriptions')}>
        <div className={cn('attachment-name')}>{filename}</div>
        <div className={cn('attachment-date')}>
          {Boolean(id) && (
            <>
              {created}
              <button className={cn('attachment-delete')} onClick={handleDelete}>
                Delete
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { Attachment }
