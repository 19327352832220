import React from 'react'
import classNames from 'classnames/bind'
import { AppContext } from 'app-context'
import { Text, TextType } from 'ui/text'

import styles from './sidebar.module.scss'

const cn = classNames.bind(styles)
type Props = React.HTMLProps<HTMLDivElement>

function Sidebar({ handleOpenPasswordModal }: any) {
  const { currentUser } = React.useContext(AppContext)
  const user = currentUser.current

  return (
    <div className={cn('container')}>
      <img width="140" height="140" src={user.avatar} className={cn('avatar')} />
      <Text className={cn('username')} type={TextType.TextLargeBold}>
        {user.company.name}
      </Text>
      <div className={cn('info')}>
        <Text className={cn('info__title', 'info__email')} type={TextType.TextLarge}>
          {user.email}
        </Text>
        {/* <Text className={cn('info__link')} type={TextType.TextLarge}>
          change e-mail
        </Text> */}
      </div>
      <div className={cn('info')}>
        <Text className={cn('info__title')} type={TextType.TextLarge}>
          **********
        </Text>
        <Text
          onClick={() => handleOpenPasswordModal()}
          className={cn('info__link')}
          type={TextType.TextLarge}
        >
          change password
        </Text>
      </div>
      {/* <div className={cn('info', '_languages')}>
        <Text className={cn('language')} type={TextType.TextLarge}>
          Russian
        </Text>
        <Text className={cn('language', '_active')} type={TextType.TextLarge}>
          English
        </Text>
      </div> */}
    </div>
  )
}

export { Sidebar }
