import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import { Button } from 'ui/button'
import { Condition } from 'ui/condition'
import { Text, TextType } from 'ui/text'
import { Resource } from 'utils/make-resource'
import { Checkbox } from 'ui/checkbox'

import styles from './register-form.module.scss'
import { api } from 'services/api'

const cn = classNames.bind(styles)

type Props = {
  availableCountries: Resource<any>
}

function Countries({ availableCountries }: Props) {
  const countries = availableCountries.read()
  const [selectedProducts, setSelectedProducts] = useState([] as any)
  const history = useHistory()

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedProducts([
        ...selectedProducts,
        countries.data.countries.find((country) => country.plan_id === event.target.value),
      ])
    } else {
      setSelectedProducts(
        selectedProducts.filter((country) => country.plan_id !== event.target.value),
      )
    }
  }

  const getTotalPrice = () => {
    return selectedProducts
      .map((product) => product.country.price)
      .reduce((acc, product) => {
        return Number(acc) + Number(product)
      }, 0)
  }

  return (
    <>
      <div className={cn('auth__logo')} onClick={() => window.open('https://himrkt.com')} />

      <div className={cn('auth__block', '_countries')}>
        <Text className={cn('auth_step')} type={TextType.TextSmall}>
          3 step to 4
        </Text>
        <Text className={cn('auth__title')} type={TextType.HeaderLarge}>
          Add countries where you want <br /> to start a business
        </Text>

        <Text className={cn('auth__country-title')} type={TextType.HeaderSmall}>
          Availaible countries
        </Text>
        <Text className={cn('auth__description')} type={TextType.TextSmall}>
          We actively working and adding new countries to this list
        </Text>
        <div className={cn('auth__checkboxes')}>
          <Condition match={countries.success}>
            {countries.data.countries.map((country) => (
              <div key={country.plan_id} className={cn('auth__checkbox')}>
                <Checkbox
                  label={
                    <div className={cn('checkbox__label')}>
                      <img
                        className={cn('auth__flag')}
                        width="22"
                        height="16"
                        src={country.country.flag}
                      />
                      {country.country.en_name}
                    </div>
                  }
                  onChange={handleCheckboxChange}
                  value={country.plan_id}
                />
              </div>
            ))}
          </Condition>
        </div>

        <div className={cn('auth__checkout')}>
          <Text type={TextType.HeaderSmall}>Checkout</Text>
          <div className={cn('prices')}>
            {selectedProducts.map((product) => {
              return (
                <div className={cn('price')} key={product.plan_id}>
                  <Text type={TextType.TextMedium} className={cn('price__name')}>
                    {product.country.en_name}
                  </Text>
                  <Text type={TextType.TextMediumBold} className={cn('price__sum')}>
                    ${product.country.price}
                  </Text>
                </div>
              )
            })}
          </div>
          <div className={cn('price', '_total')}>
            <Text type={TextType.TextMedium} className={cn('price__name')}>
              Total
            </Text>
            <Text type={TextType.HeaderSmall} className={cn('price__sum')}>
              ${getTotalPrice()}
            </Text>
          </div>
          <div className={cn('button__wrapper', '_checkout')}>
            <Button
              onClick={() =>
                history.push({ state: { selectedProducts }, pathname: '/registration/checkout' })
              }
              className={cn('button')}
              type="button"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export { Countries }
