import React, { useCallback, useContext } from 'react'
import classNames from 'classnames/bind'
import { AppContext } from 'app-context'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import styles from './indicators.module.scss'
import { Spinner } from 'ui/spinner'

const cn = classNames.bind(styles)

function Indicators() {
  const { research } = useContext(AppContext)
  const indicators: any = research.country_facts.data.economic_indicators
  return (
    <div className={cn('container')}>
      <div className={cn('indicators-blocks')}>
        {indicators.map((indicator: any) => (
          <Text key={Math.random()}>
            <div className={cn('indicators-block')}>
              <div className={cn('indicators-label')}>{indicator.name}</div>
              <div className={cn('indicators-values')}>
                {indicator.values.map((value: any) => (
                  <Text key={Math.random()}>
                    <div className={cn('indicators-group-value')}>
                      {value.icon ? (
                        <img
                          className={cn('indicators-img')}
                          width="11px"
                          height="11px"
                          src={value.icon}
                        ></img>
                      ) : (
                        ''
                      )}
                      <div className={cn('indicators-value')}>{value.value}</div>
                    </div>
                  </Text>
                ))}
              </div>
            </div>
          </Text>
        ))}
      </div>
    </div>
  )
}

export { Indicators }
