import { observable, flow, makeAutoObservable, toJS, computed } from 'mobx'

import { api, AsyncState } from 'services/api'

import { RootStore } from 'stores/root-store'

export class ResearchStore {
  @observable
  research: any
  reports: any
  local_experts: any
  country_facts: any
  top_digital_companies: any
  current_country: string
  graphs: any
  menu: any

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this)
  }

  init(id) {
    return this.loadAll(id)
  }

  loadAll = flow(function* (this: ResearchStore, id) {
    this.reports = yield api.call('GET_RESEARCH_REPORTS', { id })
    this.local_experts = yield api.call('GET_LOCAL_EXPERTS', { id })
    this.country_facts = yield api.call('GET_COUNTRY_FACTS', { id })
    this.top_digital_companies = yield api.call('GET_TOP_DIGITAL_COMPANIES', { id })
    this.menu = yield api.call('GET_CHART_MENU', { id })
    const payload: { [key: string]: any } = {
      id: this.country_facts.data.id,
      type: Object.values(toJS(this.menu.data))[0],
    }
    const graphs_data: { [key: string]: any } = {
      data: yield api.call('GET_CHART_GRAPHS', payload),
      flag: this.country_facts.data.flag,
      country_name: this.country_facts.data.en_name,
      title: Object.values(toJS(this.menu.data))[0],
    }
    this.graphs = graphs_data
    return this
  })

  currentCountry(country_id) {
    this.current_country = country_id
    return this.init(this.current_country)
  }

  getGraphs = flow(function* (this: ResearchStore, params) {
    const graphs_data: { [key: string]: any } = {
      data: yield api.call('GET_CHART_GRAPHS', params),
      flag: params.flag,
      country_name: params.country_name,
      title: params.title,
    }
    this.graphs = graphs_data
    return this.graphs
  })
}
