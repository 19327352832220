import React from 'react'
import classNames from 'classnames/bind'
import { AppContext } from 'app-context'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import styles from './content.module.scss'

const cn = classNames.bind(styles)
type Props = {
  onUpdatePaymentMethod: (id: string) => Promise<any>
  onDeletePaymentMethod: (id: string) => Promise<any>
  handleOpenPaymentModal: () => void
} & React.HTMLProps<HTMLDivElement>

function Content(props: Props) {
  const { currentUser } = React.useContext(AppContext)
  const { onUpdatePaymentMethod, onDeletePaymentMethod, handleOpenPaymentModal } = props
  const user = currentUser.current

  const getTotalPrice = (projects) => {
    return projects
      .map((product) => product.price)
      .reduce((acc, product) => {
        const total = Number(acc) + Number(product)
        if (total > 0) {
          return total
        } else {
          return 0
        }
      }, 0)
  }

  return (
    <div className={cn('container')}>
      <Text type={TextType.TextLargeBold}>
        {getTotalPrice(user.research_available_countries)}$ /month
      </Text>
      <Text type={TextType.TextLarge} className={cn('countries')}>
        {user.research_available_countries.map((country) => country.country_name).join(', ')}
      </Text>
      <Text className={cn('payment-method')} type={TextType.TextLargeBold}>
        Payment method
      </Text>
      {user.stripe.payment_methods.map((paymentMethod) => (
        <div
          key={paymentMethod.id}
          className={cn(
            'plans',
            { _purple: paymentMethod.default },
            { _gray: !paymentMethod.default },
          )}
        >
          <Text type={TextType.TextLarge} className={cn('plan')}>
            {paymentMethod.card.brand} ****{paymentMethod.card.last4}
          </Text>
          <div className={cn('actions')}>
            <Text
              type={TextType.TextLarge}
              onClick={() => {
                if (paymentMethod.default) {
                  return null
                } else {
                  onUpdatePaymentMethod(paymentMethod.id)
                }
              }}
              className={cn(
                'plan',
                { _purple: !paymentMethod.default },
                { _gray: paymentMethod.default },
              )}
            >
              {paymentMethod.default ? 'Default' : 'Make default'}
            </Text>
            <Text
              onClick={() => onDeletePaymentMethod(paymentMethod.id)}
              className={cn('delete')}
              type={TextType.TextLarge}
            >
              Delete
            </Text>
          </div>
        </div>
      ))}

      <Text
        onClick={() => handleOpenPaymentModal()}
        type={TextType.TextLarge}
        className={cn('payment-title')}
      >
        add payment method
      </Text>

      {/* <div className={cn('buttons')}>
        <div className={cn('button-wrapper', '_link')}>
          <Button className={cn('button-link')}>cancel subscribtion</Button>
        </div>
        <div className={cn('button-wrapper')}>
          <Button>Prolong subscriprion</Button>
        </div>
      </div> */}

      <div className={cn('products')}>
        {user.research_available_countries.map((country) => (
          <div key={country.country_id} className={cn('product')}>
            <Text type={TextType.HeaderSmall} className={cn('product-name')}>
              {country.country_name}
              <img width="29.33" height="21.33" src={country.flag} className={cn('product-flag')} />
            </Text>

            {/* <div className={cn('product-descriptions')}>
              <Text className={cn('product-manager')} type={TextType.TextLarge}>
                Your country manager
                <img
                  className={cn('product-avatar')}
                  width="32"
                  height="32"
                  src={project.country_manager_user.avatar}
                />
                {project.country_manager_user.name}
              </Text>
              <Text className={cn('product-languages')} type={TextType.TextLarge}>
                Speaks in: {country.country.country_languages.map((item) => item.name).join(', ')}
              </Text>
            </div> */}

            {/* <div className={cn('product-services')}>
              <Text className={cn('product-service')} type={TextType.TextLargeBold}>
                Available servises
              </Text>
              <Text className={cn('product-service', '_purple')} type={TextType.TextLarge}>
                edit servises
              </Text>
            </div> */}

            {/* <div className={cn('product-checkboxes')}>
              {project.country.country_available_services.map((service) => (
                <Text
                  key={service.name}
                  className={cn('product-checkbox')}
                  type={TextType.TextLarge}
                >
                  {service.name}
                </Text>
              ))}
            </div> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export { Content }
