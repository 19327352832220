import React from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
  const { pathname, hash } = useLocation()

  React.useEffect(() => {
    if (hash) {
      const link = document.querySelector(`[href="${hash}"]`)
      if (link) {
        ;(link as any).click()
      }
    } else {
      window.scrollTo(0, 0)
    }
  }, [pathname, hash])

  return null
}

export { ScrollToTop }
