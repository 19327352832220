import React from 'react'
import classnames from 'classnames/bind'

import { Text, TextType } from 'ui/text'

import { ReactComponent as Checkmark } from '../../assets/checkmark.svg'

import styles from './what-inside.module.scss'

const cn = classnames.bind(styles)

type Props = {
  header: React.ReactNode
} & Partial<Pick<HTMLDivElement, 'className'>>

export function WhatInside({ header, className }: Props) {
  return (
    <div className={cn('what-inside', className)}>
      {header}
      <div className={cn('what-inside__grid-container')}>
        <div className={cn('what-inside__grid')}>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>GDP Growth Rate</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Government Debt to GDP</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Employment Rate</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Interest Rate</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Balance of Trade</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Minimum Wages</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Inflation Rate</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Current Account to GDP</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Labour Costs</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Unemployment Rate</Text>
          </div>
          <div className={cn('what-inside__grid-item')}>
            <Checkmark />
            <Text type={TextType.TextXLargeBold}>Credit Rating</Text>
          </div>
        </div>
      </div>
    </div>
  )
}
