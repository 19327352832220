import React from 'react'
import classNames from 'classnames/bind'
import { useHistory } from 'react-router-dom'

import styles from './footer.module.scss'
import { ReactComponent as TopWave } from './assets/wave.svg'

const cx = classNames.bind(styles)
type Props = {
  topChild?: React.ReactNode
}

const Footer = ({ topChild = null }: Props) => {
  const history = useHistory()
  return (
    <footer className={cx('footer', { 'pre-footer': !!topChild })}>
      <TopWave className={cx('footer__corner')} />
      {topChild}
      <div className={cx('layout-center-wrapper')}>
        <div className={cx('links')} onClick={() => window.open('https://himrkt.com')}>
          <div className={cx('link', '_logo')} />
          <div className={cx('link', '_caption')}>Easiest way to enter new markets</div>
        </div>
        <div className={cx('links-wrapper')}>
          <div className={cx('links')}>
            <div className={cx('link', 'link-header')}>
              <span>COMPANY</span>
            </div>
            <div className={cx('link')} onClick={() => history.push('/country-manager')}>
              About Country Managers
            </div>
            <div className={cx('link')} onClick={() => history.push('/partnership')}>
              Info for partners
            </div>
            <div className={cx('link')}>Contacts</div>
          </div>
          <div className={cx('links')}>
            <div className={cx('link', 'link-header')}>
              <span>SUPPORT</span>
            </div>
            <div className={cx('link')} onClick={() => history.push('/privacy-policy')}>
              Privacy Policy
            </div>
            <div className={cx('link')} onClick={() => history.push('/terms-of-use')}>
              Terms of use
            </div>
          </div>
        </div>
      </div>
      <div className={cx('copyright')}>&#169; {new Date().getFullYear()} himrkt.com</div>
    </footer>
  )
}

export { Footer }
