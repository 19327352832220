import React from 'react'
import { observer } from 'mobx-react'
import { useHistory, useParams } from 'react-router-dom'

import { AppContext } from 'app-context'

import { RegisterForm as RegisterFormComponent } from '../../components/register-form'
import { RegistrationSteps } from '../../types'
import { api } from 'services/api'

type Props = React.HTMLProps<HTMLDivElement>

function RegisterFormContainer(props: Props) {
  const { stepId } = useParams<{ stepId: RegistrationSteps }>()
  const history = useHistory()

  const { auth } = React.useContext(AppContext)

  const register = React.useCallback(
    async (evt) => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        api.forgot()
        history.push('/mobile-error')
      } else {
        const result = await auth.register(evt)
        if (result.success) {
          history.push(RegistrationSteps.COMPANY)
        }

        return result
      }
    },
    [auth.register],
  )

  const saveCompany = React.useCallback(
    async (evt) => {
      const result = await auth.saveCompany(evt)
      if (result.success) {
        history.push(RegistrationSteps.COUNTRIES)
      }

      return result
    },
    [auth.saveCompany],
  )

  return (
    <RegisterFormComponent
      stepId={stepId}
      register={register}
      saveCompany={saveCompany}
      availableCountries={auth.availableCountries}
      {...props}
    />
  )
}

const ObservedRegisterFormContainer = observer(RegisterFormContainer)

export { ObservedRegisterFormContainer as RegisterForm }
