import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { AppContext } from 'app-context'
import arrow from '../custom-select/assets/select-arrow.svg'
import { Spinner } from 'ui/spinner'

const Main = styled('div')`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: -0.75px;

  color: #000000;
  margin-right: auto;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 8px 16px;

  background: #ffffff;
  border: 3px solid #000000;
  box-sizing: border-box;
  /* Big Shadow */

  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 8px;
`

const DropDownContainer = styled('div')`
  width: 240px;
`

const DropDownHeader = styled('div')``

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: 100;
  width: 255px;
`

const DropDownList = styled('ul')`
  padding: 20px;
  margin-left: -20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgb(60 0 128 / 5%), 0px 2px 16px rgb(60 0 128 / 5%);
  box-sizing: border-box;
  border: 1px solid #ebe8ed;
  color: #000000;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: -0.75px;
`

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
  display: flex;
  &:hover {
    color: #a04dff;
  }
`

const DropDownHeaderValue = styled('div')`
  display: flex;
`

const Option = styled('div')`
  margin-left: 2px;
`

const OptionSelected = styled('div')`
  margin-left: 2px;
  color: #a04dff;
`
const SelectArrow = styled('div')`
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: center;
  height: 10px;
  width: 14px;
  place-self: center;
  margin-left: auto;
`

function CustomSelect() {
  const { currentUser } = useContext(AppContext)
  const store = useContext(AppContext)
  const options: any = Object.values(currentUser.current.research_available_countries)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0])

  const toggling = () => setIsOpen(!isOpen)

  const [isLoaded, setLoad] = useState(false)

  const onOptionClicked = (option: any) => async () => {
    setLoad(true)
    setSelectedOption(option)
    setIsOpen(false)
    await store.research.loadAll(option.country_id)
    setTimeout(() => {
      setLoad(false)
    }, 2 * 1000)
  }

  return (
    <Main>
      {isLoaded ? (
        <Spinner type={'fullbody'} />
      ) : (
        <DropDownContainer>
          <DropDownHeader onClick={toggling}>
            {(
              <DropDownHeaderValue>
                <img width="24px" height="24px" src={selectedOption.flag}></img>
                <Option>{selectedOption.country_name}</Option>
                <SelectArrow></SelectArrow>
              </DropDownHeaderValue>
            ) || (
              <DropDownHeaderValue>
                <img width="24px" height="24px" src={options[0].flag}></img>
                <Option>{options[0].country_name}</Option>
                <SelectArrow></SelectArrow>
              </DropDownHeaderValue>
            )}
          </DropDownHeader>
          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                {options.map((option: any) => (
                  <ListItem
                    onClick={onOptionClicked(option)}
                    key={option.country_id}
                    data-key={option.country_id}
                  >
                    <img width="24px" height="24px" src={option.flag}></img>{' '}
                    {selectedOption.country_name === option.country_name ? (
                      <OptionSelected>{option.country_name}</OptionSelected>
                    ) : (
                      <Option>{option.country_name}</Option>
                    )}
                  </ListItem>
                ))}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      )}
    </Main>
  )
}

export { CustomSelect }
