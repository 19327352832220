import React from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames/bind'

import { Text, TextType } from 'ui/text'
import { FinalFormField } from 'ui/final-form-field'
import { api } from 'services/api'

import { Layout } from '../../components/layout/layout'
import HowIt1 from '../../assets/how-it-1.png'
import HowIt2 from '../../assets/how-it-2.png'
import HowIt3 from '../../assets/how-it-3.png'

import styles from './mobile-error.module.scss'

const cn = classnames.bind(styles)

function MobileError() {
  const history = useHistory()

  return (
    <div className={cn('mobile-error')}>
      <div className={cn('logo')}></div>
      <div className={cn('header')}>Sorry, so far we only support desktop browsers.</div>
      <div className={cn('body')}></div>
      <div className={cn('footer')}>Mobile support coming soon.</div>
    </div>
  )
}

export { MobileError }
