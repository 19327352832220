import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames/bind'
import { useHistory } from 'react-router-dom'
import { AppContext } from 'app-context'
import { NotificationList } from 'features/notifications/components/notification-list'
import { Text, TextType } from 'ui/text'

import styles from './clients.module.scss'

const cn = classNames.bind(styles)
type Props = React.HTMLProps<HTMLDivElement>

function Clients(props: Props) {
  const { className, ...rest } = props
  const { clients, notifications } = React.useContext(AppContext)
  const aviableClients = clients.availableClients.read()
  const [showMore, setShowMore] = useState(false)
  const history = useHistory()

  let items = notifications.items
  let slicedItems = notifications.items.slice(0, 4)

  return (
    <>
      <div className={cn('clients')}>
        <Text type={TextType.HeaderSmall} className={cn('clients-title', className)}>
          Clients
        </Text>
        {aviableClients.map((client) => (
          <Text
            type={TextType.TextLargeBold}
            className={cn('country', className)}
            key={client.country.id}
          >
            <img className={cn('flag')} width="22" height="16" src={client.country.flag} />

            {client.country.en_name}

            {client.companies.map((company) => (
              <div
                onClick={() => history.push(`/clients/${company.id}`)}
                key={company.id}
                className={cn('company-block', className)}
              >
                <div className={cn('company-logo')}>
                  <img width="64" height="64" src={company.logo} />
                </div>
                <div className={cn('company-text')}>
                  <Text type={TextType.TextMediumBold} className={cn('company', className)}>
                    {company.name}
                  </Text>
                  <Text type={TextType.TextSmall} className={cn('company-name', className)}>
                    Company name
                  </Text>
                  <Text type={TextType.TextSmall} className={cn('company-country', className)}>
                    {company.country}
                  </Text>
                  <Text type={TextType.TextSmall} className={cn('company-user', className)}>
                    {company.user.email}
                  </Text>
                </div>
              </div>
            ))}
          </Text>
        ))}
      </div>
      <div className={cn('notifications')}>
        <Text type={TextType.HeaderSmall} className={cn('clients-title', className)}>
          Latest notifications
        </Text>
        <div className={cn('notification-list')}>
          <NotificationList items={showMore ? items : slicedItems} />
          <Text
            onClick={() => (showMore ? setShowMore(false) : setShowMore(true))}
            type={TextType.TextLargeBold}
            className={cn('notification-more')}
          >
            {showMore ? 'Less' : 'More'}
          </Text>
        </div>
      </div>
    </>
  )
}

const ObservedClients = observer(Clients)

export { ObservedClients as Clients }
