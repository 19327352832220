import { AuthStore } from '../features/auth/stores/auth-store'
import { CheckoutStore } from '../features/checkout/stores/checkout-store'
import { ProjectStore } from '../features/tasks/stores/project-store'
import { ClientsStore } from '../features/clients/stores/clients-store'
import { SettingStore } from '../features/settings/stores/setting-store'
import { ResearchStore } from '../features/research/stores/research-store'
import { CurrentUser } from './current-user'
import { BootstrapResources } from './bootstrap-resources'
import { NotificationsStore } from '../features/notifications/stores/notification-store'

export class RootStore {
  currentUser: CurrentUser
  auth: AuthStore
  checkout: CheckoutStore
  project: ProjectStore
  clients: ClientsStore
  notifications: NotificationsStore
  bootstrapResources: BootstrapResources
  settings: SettingStore
  research: ResearchStore
  constructor() {
    this.currentUser = new CurrentUser(this)
    this.bootstrapResources = new BootstrapResources(this)
    this.auth = new AuthStore(this)
    this.checkout = new CheckoutStore(this)
    this.project = new ProjectStore(this)
    this.clients = new ClientsStore(this)
    this.settings = new SettingStore(this)
    this.research = new ResearchStore(this)
    this.notifications = new NotificationsStore(this)
  }
}
