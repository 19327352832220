import React, { useCallback, useContext } from 'react'
import classNames from 'classnames/bind'
import { AppContext } from 'app-context'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import styles from './digital-companies.module.scss'
import { Spinner } from 'ui/spinner'

const cn = classNames.bind(styles)

function DigitalCompanies(data) {
  const { research } = useContext(AppContext)
  const digitalCompanies: any = research.top_digital_companies.data
  return (
    <React.Suspense fallback={<Spinner />}>
      <div className={cn('container')}>
        <div className={cn('digital-companies-blocks')}>
          {digitalCompanies.map((company: any) => (
            <Text key={Math.random()}>
              <div className={cn('digital-companies-block')}>
                <img src={company.logo} className={cn('digital-companies-logo')}></img>
                <div className={cn('digital-companies-name')}>{company.name}</div>
                <div className={cn('digital-companies-description')}>{company.description}</div>
                <div className={cn('digital-companies-info')}>
                  <div className={cn('digital-companies-price-img')}></div>
                  <div className={cn('digital-companies-price')}>{company.projectSize}</div>
                  <div className={cn('digital-companies-size-img')}></div>
                  <div className={cn('digital-companies-size')}>{company.peopleCount}</div>
                  <div className={cn('digital-companies-cost-img')}></div>
                  <div className={cn('digital-companies-cost')}>{company.costHour}</div>
                  <div className={cn('digital-companies-location-img')}></div>
                  <div className={cn('digital-companies-location')}>{company.location}</div>
                </div>
              </div>
            </Text>
          ))}
        </div>
      </div>
    </React.Suspense>
  )
}

export { DigitalCompanies }
