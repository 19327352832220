import React, { useCallback, useContext } from 'react'
import classNames from 'classnames/bind'
import { AppContext } from 'app-context'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import styles from './facts.module.scss'
import report_blank_src from '../../assets/report_blank.svg'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { Spinner } from 'ui/spinner'
import { FactsMap } from './facts-map'

const cn = classNames.bind(styles)

function Facts() {
  const { research } = useContext(AppContext)
  const facts: any = research.country_facts.data
  return (
    <div className={cn('container')}>
      <div className={cn('country-facts')}>
        <div className={cn('country-facts-left')}>
          <div className={cn('country-facts-header')}>
            <div className={cn('country-facts-flag-cover')}>
              <img className={cn('country-facts-flag')} src={facts.flag}></img>
            </div>

            <label className={cn('country-facts-name')}>{facts.en_name}</label>
          </div>
          <div className={cn('country-facts-body')}>
            <label className={cn('label-facts')}>
              <span className={cn('label-name')}>President</span>
              <div className={cn('country-facts-president-values')}>
                <div className={cn('country-facts-president-avatar-cover')}>
                  <img
                    src={facts.president_avatar}
                    className={cn('country-facts-president-avatar')}
                  ></img>
                </div>

                <div className={cn('country-facts-president')}>{facts.president}</div>
              </div>
            </label>
            <label className={cn('label-facts')}>
              <span className={cn('label-name')}>GDP (PPP)</span>
              <div className={cn('country-facts-gdp-values')}>
                <div className={cn('country-facts-gdp')}>{facts.gdp_total}</div>
                <div className={cn('country-facts-gdp-description')}>total</div>
              </div>
              <div className={cn('country-facts-gdp-per-cap')}>
                <div className={cn('country-facts-gdp')}>{facts.gdp_per_cap}</div>
                <div className={cn('country-facts-gdp-description')}>per capita</div>
              </div>
              <div className={cn('country-facts-gdp-estimate')}>{facts.gdp_last_update}</div>
            </label>
            <label className={cn('label-facts')}>
              Population
              <div className={cn('country-facts-population')}>
                <div className={cn('country-facts-population-value')}>{facts.population}</div>
                <div className={cn('country-facts-population-estimate')}>
                  {facts.population_last_update}
                </div>
              </div>
            </label>
            <label className={cn('label-language')}>
              Languages
              <div className={cn('country-facts-languages')}>
                {facts.country_languages.map((language: any) => (
                  <Text className={cn('country-facts-language')} key={Math.random()}>
                    {language.name}
                  </Text>
                ))}
              </div>
            </label>
            <label className={cn('label-facts-currency')}>
              Currency
              <div className={cn('country-facts-currencies')}>
                {facts.currencies.map((currency: any) => (
                  <Text className={cn('country-facts-currency-block')} key={Math.random()}>
                    <div className={cn('country-facts-currency')}>{currency.code}</div>
                    <div className={cn('country-facts-currency-symbol')}>
                      {currency.name} ({currency.symbol})
                    </div>
                  </Text>
                ))}
              </div>
            </label>
            <label className={cn('label-facts-time-zone')}>
              Time Zone
              <div className={cn('country-facts-time-zones')}>
                {facts.timezone_utc.map((timezone: any) => (
                  <Text className={cn('country-facts-time-zone')} key={Math.random()}>
                    {timezone}
                  </Text>
                ))}
              </div>
            </label>
          </div>
        </div>
        <div className={cn('country-facts-right')}>
          <FactsMap id={facts.alpha3code} className={cn('header-map')} />
        </div>
      </div>
    </div>
  )
}

export { Facts }
