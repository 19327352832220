import React from 'react'
import classNames from 'classnames/bind'

import styles from './button.module.scss'

const cn = classNames.bind(styles)
type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

function Button(props: Props) {
  const { className, type, children, ...rest } = props

  return (
    <button {...rest} className={cn('button', className)} type={type}>
      {children}
    </button>
  )
}

export { Button }
