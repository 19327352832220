import React from 'react'
import classNames from 'classnames/bind'
import { useHistory } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Text, TextType } from 'ui/text'

import styles from './header.module.scss'
const cn = classNames.bind(styles)

type Props = React.HTMLProps<HTMLDivElement>

const HeaderDesktop = (props: Props): JSX.Element => {
  const history = useHistory()

  return (
    <header className={cn('header')}>
      <div className={cn('header-wrapper')}>
        <div className={cn('nav')}>
          <div className={cn('nav-items')}>
            <div
              className={cn('nav-item', '_logo')}
              onClick={() => window.open('https://himrkt.com')}
            />
          </div>
          <div className={cn('nav-items', '_links')}>
            <Text
              type={TextType.TextMedium}
              className={cn('nav-item')}
              onClick={() => history.push('/#services')}
            >
              Services
            </Text>
            <Text
              type={TextType.TextMedium}
              className={cn('nav-item')}
              onClick={() => history.push('/#benefits')}
            >
              Your benefits
            </Text>

            <Text
              onClick={() => history.push('/country-manager')}
              type={TextType.TextMedium}
              className={cn('nav-item')}
            >
              Country manager
            </Text>
            <Text
              onClick={() => history.push('/auth')}
              type={TextType.TextMedium}
              className={cn('nav-item')}
            >
              Login
            </Text>
            <Text
              onClick={() => history.push('/registration/idendity')}
              type={TextType.TextMedium}
              className={cn('nav-item', '_login')}
            >
              Get started
            </Text>
          </div>
        </div>
      </div>
    </header>
  )
}

export { HeaderDesktop }
