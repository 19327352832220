import React, { useCallback, useContext } from 'react'
import classNames from 'classnames/bind'
import { AppContext } from 'app-context'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import styles from './experts.module.scss'
import { Spinner } from 'ui/spinner'

const cn = classNames.bind(styles)

function Experts(data) {
  const { research } = useContext(AppContext)
  const experts: any = research.local_experts.data
  return (
    <div className={cn('container')}>
      <div className={cn('experts-blocks')}>
        {experts.map((expert: any) => (
          <Text key={Math.random()}>
            <a href={expert.source} target="_blank" rel="noreferrer">
              <div className={cn('experts-block')}>
                <div className={cn('experts-block-avatar-cover')}>
                  <img className={cn('experts-block-avatar')} src={expert.avatar}></img>
                </div>

                <div className={cn('experts-block-info')}>
                  <div className={cn('experts-block-name')}>
                    {expert.name} {expert.surname}
                  </div>
                  {expert.occupation ? (
                    <div className={cn('experts-block-occupation')}>
                      <div className={cn('occupation-icon')}></div>
                      {expert.occupation}
                    </div>
                  ) : (
                    <></>
                  )}

                  {expert.company ? (
                    <div className={cn('experts-block-company')}>
                      <div className={cn('company-icon')}></div>
                      {expert.company}
                    </div>
                  ) : (
                    <></>
                  )}

                  {expert.email ? (
                    <div className={cn('contacts')}>
                      <div className={cn('email-icon')}></div>
                      {expert.email}
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <div className={cn('contacts')}>
                  <div className={cn('phone-icon')}></div>
                  {expert.phone}
                </div> */}
                </div>
                <img
                  src={expert.social_network.icon}
                  className={cn('experts-block-social-network')}
                ></img>
              </div>
            </a>
          </Text>
        ))}
      </div>
    </div>
  )
}

export { Experts }
