import { hot } from 'react-hot-loader/root'
import { BrowserRouter } from 'react-router-dom'
import { AppContext } from 'app-context'
import { ScrollToTop } from 'ui/scroll-to-top'
import { BreakpointsProvider } from 'ui/breakpoints-provider'
import { RenderRoutes } from 'components/routes'

import { routes } from './app-routes'

import './app.module.scss'

function App() {
  return (
    <AppContext.Consumer>
      {(ctx) => (
        <AppContext.Provider value={ctx}>
          <BrowserRouter>
            <ScrollToTop />
            <BreakpointsProvider>
              <RenderRoutes routes={routes} />
            </BreakpointsProvider>
          </BrowserRouter>
        </AppContext.Provider>
      )}
    </AppContext.Consumer>
  )
}

const AppWithDevelopmentMode = process.env.NODE_ENV === 'development' ? hot(App) : App

export { AppWithDevelopmentMode as App }
