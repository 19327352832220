import { useState, useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { observer } from 'mobx-react'

import { AppContext } from 'app-context'

import { CardDetailModal } from '../../components/card-detail'
import { Access } from 'common/types'

type Props = React.HTMLProps<HTMLDivElement>

const CardDetailContainer = () => {
  const { project, currentUser } = useContext(AppContext)

  const history = useHistory()
  const { companyId } = useParams()

  const currentTask = project.currentTask
  if (project.currentTaskId) {
    project.taskResource.read()
  } else {
    return null
  }

  const isFinished = project.task.task_check_list.every((item) => item.status === true)

  const handleCheckboxChange = async (event) => {
    const value = event.target.checked
    const name = event.target.name
    const id = event.target.id

    const formData = new FormData()

    const data = {
      id,
      status: value,
    }
    formData.append(name, JSON.stringify(data))
    formData.append('_method', 'PUT')

    await project.updateTask({
      taskId: project.currentTask.id,
      projectId: project.currentProjectId,
      formData,
    })
  }
  const handleCardDetailClose = () => {
    if (currentUser.current.role === Access.CUSTOMERDASHBOARD) {
      history.push(`/tasks?projectId=${project.currentProjectId}`)
    }
    if (currentUser.current.role === Access.COUNTRY_MANAGER) {
      history.push(`/clients/${companyId}?projectId=${project.currentProjectId}`)
    }
  }

  const handleStatusChange = (id) => {
    const formData = new FormData()
    formData.append('_method', 'PUT')
    formData.append('status', id)

    project.updateTask({
      taskId: project.currentTask.id,
      projectId: project.currentProjectId,
      formData,
    })
  }

  const uploadAttachments = (e) => {
    const { files } = e.target
    const filesArray = Array.from(files)

    const formData = new FormData()
    filesArray.forEach((file) => {
      formData.append('files[]', file as any)
    })

    project.uploadAttachment({
      projectId: project.currentProjectId,
      taskId: project.currentTask.id,
      formData,
    })
  }

  const deleteAttachment = (attachId) => {
    const formData = new FormData()
    formData.append('_method', 'DELETE')
    formData.append('attach_id', attachId)

    project.deleteAttachment({
      formData,
      projectId: project.currentProjectId,
      taskId: project.currentTask.id,
    })
  }

  return (
    <CardDetailModal
      handleStatusChange={handleStatusChange}
      handleCardDetailClose={handleCardDetailClose}
      task={project.task}
      currentTask={currentTask}
      isFinished={isFinished}
      userAvatar={currentUser.current.avatar}
      handleCheckboxChange={handleCheckboxChange}
      deleteAttachment={deleteAttachment}
      uploadAttachments={uploadAttachments}
    />
  )
}

const observedCardDetailContainer = observer(CardDetailContainer)
export { observedCardDetailContainer as CardDetailContainer }
