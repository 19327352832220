import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import classnames from 'classnames/bind'
import { api } from 'services/api'
import { useToggle } from 'utils/useToggle'
import { Condition } from 'ui/condition'
import { OutsideClickHandler } from 'ui/outside-click-handler'
import { AppContext } from 'app-context'
import { NotificationsWidget } from 'features/notifications'
import { useVisibleState } from 'utils/useVisibleState'

import { ReactComponent as TasksIcon } from '../../assets/tasks_icon.svg'
import { ReactComponent as SettingsIcon } from '../../assets/settings_icon.svg'
import { ReactComponent as NotificationIcon } from '../../assets/notifications_icon.svg'

import { CustomSelect } from '../../../research/components/custom-select'
import { AddCountry } from '../../../research/components/addcountry'
import { DoBuyModal } from '../../../research/components/dobuymodal'

import styles from './header.module.scss'

const cn = classnames.bind(styles)
function Header() {
  const { currentUser, checkout, notifications } = useContext(AppContext)
  const [error, setError]: any = useState(null)
  const [isMenuVisible, setIsMenuVisible] = useToggle()
  const notificationsDropdown = useVisibleState()
  const history = useHistory()
  const user = currentUser.current
  const [researchHeader, setResearchHeader] = useState(true)

  const { visible, open, close } = useVisibleState()
  const { visible: visibleDoBuy, open: openDoBuy, close: closeDoBuy } = useVisibleState()

  const handleClickSettings = () => {
    setResearchHeader(false)
  }
  const handleClickResearch = () => {
    setResearchHeader(true)
    history.push('/research')
  }
  const createSubscription = ({ paymentMethodId, planIds, productId }) => {
    checkout
      .subscribe({
        paymentMethodId,
        product_id: productId,
        plan_ids: planIds,
        coupon: '',
      })
      .then((result) => {
        if (result.code === 1039003) {
          setError(result.data)
        }
        if (result.success) {
          const timer = setTimeout(() => {
            window.location.reload()
          }, 5000)
          return () => clearTimeout(timer)
        } else {
          setError('An error occurred during payment, please contact support')
        }
      })
  }

  const onCreateSubscription = React.useCallback(async (selectedProducts) => {
    const paymentMethodId = user.stripe.payment_methods.map((paymentMethod) => {
      if (paymentMethod.default) {
        return paymentMethod.id
      }
    })
    const result = await createSubscription({
      paymentMethodId: paymentMethodId[0],
      productId: selectedProducts[0].product_id,
      planIds: selectedProducts.map((product) => product.plan_id),
    })
    // closeDoBuy()
  }, [])

  return (
    <div className={cn('header')}>
      <div className={cn('header-items')}>
        <div className={cn('header-logo')} onClick={handleClickResearch} />
        {researchHeader ? (
          <>
            {user.id !== 9 ? (
              <>
                <CustomSelect />
                <AddCountry handleOpenDoBuyModal={openDoBuy} />
              </>
            ) : (
              <></>
            )}

            <DoBuyModal
              onCreateSubscription={onCreateSubscription}
              error={error}
              setError={setError}
              visible={visibleDoBuy}
              handleCloseDoBuyModal={closeDoBuy}
            />
          </>
        ) : (
          <></>
        )}
        <div className={cn('header-links')}>
          {/* <div
            className={cn('header-notifications', {
              _active: notificationsDropdown.visible,
            })}
            onClick={() => notificationsDropdown.open()}
          >
            <NotificationIcon />
            {!!notifications.count && (
              <span className={cn('header-notifications__label')}>{notifications.count}</span>
            )}
          </div> */}
          <NavLink
            activeClassName={cn('_active')}
            className={cn('header-link')}
            onClick={handleClickSettings}
            to="/settings"
          >
            <SettingsIcon />
          </NavLink>

          <div className={cn('header-link', '_user')} onClick={() => setIsMenuVisible(true)}>
            <img
              src={user.avatar}
              alt="avatar"
              width="24"
              height="24"
              className={cn('user-avatar')}
            />
            <div className={cn('user-name')}>{user?.company?.name || 'Country Manager'}</div>
            <div className={cn('user-arrow')} />
          </div>
        </div>
      </div>
      <Condition match={notificationsDropdown.visible}>
        <div className={cn('notifications')}>
          <NotificationsWidget items={notifications.items} onClose={notificationsDropdown.close} />
        </div>
      </Condition>
      <Condition match={isMenuVisible}>
        <OutsideClickHandler onOutsideClick={() => setIsMenuVisible(false)}>
          <div className={cn('menu')}>
            <div className={cn('menu-link')} onClick={() => api.exit()}>
              Log out
            </div>
          </div>
        </OutsideClickHandler>
      </Condition>
    </div>
  )
}

const observedHeader = observer(Header)
export { observedHeader as Header }
