import { AxiosInstance } from 'axios'

import { EndpointType } from 'services/api/types'

enum URL {
  AVAILABLE_CLIENTS = 'api/cabinet/companies',
}

const AVAILABLE_CLIENTS: EndpointType<any, any> = (data: any, fetcher: AxiosInstance, headers) => {
  return fetcher.request({
    method: 'get',
    url: URL.AVAILABLE_CLIENTS,
    headers,
  })
}

export const CLIENTS_API = {
  AVAILABLE_CLIENTS,
}
