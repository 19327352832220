import { useCallback, useState } from 'react'

export function useFormError(onSubmit) {
  const [message, setMessage] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [succceeded, setSuccceeded] = useState<boolean>(false)

  const close = useCallback(() => {
    setMessage('')
  }, [setMessage])

  const submit = useCallback(
    async (values) => {
      setSubmitting(true)
      const result = await onSubmit(values)
      console.info(result)
      if (!result.success) {
        setMessage(result?.message)
        setSubmitting(false)
        return result
      }

      if (result.success) {
        setSuccceeded(true)
      }
      setSubmitting(false)
    },
    [onSubmit],
  )

  return {
    message,
    submitting,
    succceeded,
    handlers: {
      close,
      submit,
    },
  }
}
