import React from 'react'
import classNames from 'classnames/bind'
import styles from './addcountry.module.scss'

const cn = classNames.bind(styles)
type Props = {
  handleOpenDoBuyModal: () => void
} & React.HTMLProps<HTMLDivElement>

function AddCountry(props: Props) {
  const { handleOpenDoBuyModal } = props

  return (
    <div className={cn('add-country')} onClick={() => handleOpenDoBuyModal()}>
      <div className={cn('plus-icon')}></div>
      <div className={cn('title')}>add country</div>
      <div className={cn('price')}>9.99$</div>
    </div>
  )
}

export { AddCountry }
