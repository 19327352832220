import { useBreakpoints, BREAKPOINTS } from './utils/use-breakpoints'
import { breakpointsCtx } from './utils/breakpoints-ctx'

function BreakpointsProvider(props): JSX.Element {
  const breakpoints = useBreakpoints<typeof BREAKPOINTS>(BREAKPOINTS)

  return <breakpointsCtx.Provider value={breakpoints} {...props} />
}

export { BreakpointsProvider }
