import React from 'react'
import { Route, RouteProps, RouteComponentProps, Redirect } from 'react-router-dom'

import { AuthorizedLayout } from 'features/authorized-layout'
import { Spinner } from 'ui/spinner'
import { api } from 'services/api'

type TAuthRoutePropsBase = Omit<RouteProps, 'children'>

type TAuthRouteProps<T> = TAuthRoutePropsBase & {
  component?: T
}

export function AuthRoute({
  component: Component,
  render,
  ...props
}: TAuthRouteProps<any>): JSX.Element {
  const renderOrRedirect = (props: RouteComponentProps): JSX.Element => {
    if (api.isAuth) {
      return typeof render === 'function' ? render(props) : <Component {...props} />
    }

    return (
      <Redirect
        to={{
          pathname: '/auth',
          search: window.location.search,
          state: { from: props.location },
        }}
      />
    )
  }

  return <Route {...props} render={renderOrRedirect} />
}
