import { AxiosInstance } from 'axios'

import { EndpointType } from 'services/api/types'

enum URL {
  GET_CUSTOMER = 'api/cabinet/stripe/payment',
  SUBSCRIBE = 'api/cabinet/stripe/subscriptions',
  CHECKPROMOCODE = 'promo/check',
}

const GET_CUSTOMER: EndpointType = (data: any, fetcher: AxiosInstance, headers) =>
  fetcher.request<any>({
    method: 'get',
    url: URL.GET_CUSTOMER,
    headers,
  })

const SUBSCRIBE: EndpointType = (data: any, fetcher: AxiosInstance, headers) =>
  fetcher.request<any>({
    method: 'post',
    url: URL.SUBSCRIBE,
    headers,
    data,
  })
const CHECKPROMOCODE: EndpointType = (data: any, fetcher: AxiosInstance, headers) =>
  fetcher.request<any>({
    method: 'post',
    url: URL.CHECKPROMOCODE,
    headers,
    data,
  })

export const CHECKOUT_API = {
  GET_CUSTOMER,
  SUBSCRIBE,
  CHECKPROMOCODE,
}
