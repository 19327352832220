import React from 'react'
import classnames from 'classnames/bind'

import styles from './checkbox.module.scss'

const cn = classnames.bind(styles)
type Props = {
  onChange?: Function
  onClick?: Function
  disabled?: boolean
  value?: string | number
  checked?: boolean
  label?: string | number | any
  name?: string
  id?: string
} & Omit<React.HTMLProps<HTMLInputElement>, 'label'>

const Checkbox = ({
  onChange,
  onClick,
  disabled,
  value,
  checked,
  label,
  name,
  id,
}: Props): JSX.Element => {
  return (
    <label className={cn('container', { _checked: checked })}>
      <input
        checked={checked}
        className={cn('input', { _checked: checked })}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        onClick={onClick}
        type="checkbox"
        value={value}
      />
      <span className={cn('checkmark')}></span>
      <p className={cn('label')}>{label}</p>
    </label>
  )
}

export { Checkbox }
