import { useContext, useState } from 'react'
import { Modal, ModalProps } from 'react-responsive-modal'
import classnames from 'classnames/bind'
import { useVisibleState } from 'utils/useVisibleState'
import { observer } from 'mobx-react'
import { Button } from 'ui/button'
import { Checkbox } from 'ui/checkbox'
import { AppContext } from 'app-context'

import 'react-responsive-modal/styles.css'

import { Attachment } from '../attachment/attachment'
import { LoadingAttachments } from '../loading-attachments/loading-attachments'

import styles from './card-detail.module.scss'

const cn = classnames.bind(styles)

const statuses = [
  {
    id: 0,
    name: 'I’ll do it later',
  },
  {
    id: 1,
    name: 'Working on it',
  },
  {
    id: 2,
    name: 'My job here is done',
  },
]

function CardDetailModal(props: any) {
  const {
    handleCardDetailClose,
    handleCheckboxChange,
    uploadAttachments,
    deleteAttachment,
    task,
    userAvatar,
    isFinished,
    handleStatusChange,
  } = props
  const { project } = useContext(AppContext)
  const { visible } = useVisibleState(!!Object.keys(task))

  const [form, setForm] = useState<any>({
    comment: '',
  })

  const handleCommentChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  }

  const handleCommentSubmit = async (event) => {
    event.preventDefault()
    const formData = new FormData()

    formData.append('comment', form.comment)

    const result = await project.createComment({
      taskId: project.currentTask.id,
      projectId: project.currentProjectId,
      formData,
    })

    if (result.success) {
      setForm({
        comment: '',
      })
    }
  }

  return (
    <div>
      <Modal
        open={visible}
        onClose={handleCardDetailClose}
        center
        classNames={{
          modal: cn('modal'),
        }}
      >
        <div className={cn('modal-title')}>{task.title}</div>
        <div className={cn('modal-description')}>{task.description}</div>
        <div className={cn('modal-box')}>
          <div className={cn('box-title')}>Checklist</div>
          <div className={cn('box-checkboxes')}>
            {task.task_check_list.map((item) => (
              <div key={item.id} className={cn('box-checkbox')}>
                <Checkbox
                  checked={item.status}
                  onChange={handleCheckboxChange}
                  id={item.id}
                  name="checkList"
                  value={item.check_list.name}
                  label={item.check_list.name}
                />
              </div>
            ))}

            <div className={cn('box-statuses')}>
              <div className={cn('status-title')}>Status</div>
              <div className={cn('statuses')}>
                {statuses.map((status) => (
                  <div
                    key={status.id}
                    className={cn('status', {
                      _active: status.id === task.status,
                      _disabled: !isFinished && status.id === 2,
                    })}
                    onClick={() => {
                      if (!isFinished && status.id === 2) {
                        return null
                      }
                      handleStatusChange(status.id)
                    }}
                  >
                    {status.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={cn('modal-attachments')}>
          <div className={cn('attachments-title')}>Attachments</div>
          <div className={cn('attachments')}>
            {task.task_attachments.map((attachment) => (
              <Attachment
                key={attachment.id}
                id={attachment.id}
                filename={attachment.storage_file.name}
                src={attachment.storage_file.path_src}
                created={`by ${attachment.author} ${attachment.storage_file.created_at}`}
                deleteAttachment={deleteAttachment}
              />
            ))}
            {/* <LoadingAttachments items={loadingAttachments} /> */}
          </div>
          <input
            type="file"
            multiple={true}
            className={cn('attachment-input')}
            accept=".jpg, .jpeg, .png, .svg, .pdf, .doc,.docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            onChange={uploadAttachments}
          />
        </div>
        <div className={cn('modal-comments')}>
          <div className={cn('comments-title')}>Comments</div>
          <form onSubmit={handleCommentSubmit}>
            <div className={cn('comments-form')}>
              <img
                src={userAvatar}
                className={cn('comments-avatar')}
                width="32"
                height="32"
                alt="avatar"
              />
              <textarea
                onChange={handleCommentChange}
                placeholder="Write here…"
                name="comment"
                value={form.comment}
                rows={3}
                className={cn('comments-textarea')}
              />
            </div>
            <div className={cn('comments-button-wrapper')}>
              <Button disabled={!form.comment} type="submit" className={cn('comments-button')}>
                Send
              </Button>
            </div>
          </form>
          <div className={cn('comments-blocks')}>
            {task.comments.map((commentary) => (
              <div key={commentary.id} className={cn('comments-block')}>
                <img
                  src={commentary.user_avatar}
                  className={cn('comments-avatar')}
                  width="32"
                  height="32"
                  alt="avatar"
                />
                <div className={cn('comments-messages')}>
                  <div className={cn('comments-message')}>{commentary.comment}</div>
                  <div className={cn('comments-time')}>{commentary.created_at}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  )
}

const observedCardDetailModal = observer(CardDetailModal)
export { observedCardDetailModal as CardDetailModal }
