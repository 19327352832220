import { AxiosInstance } from 'axios'

import { EndpointType } from 'services/api/types'

enum URL {
  GET_PROJECTS = 'api/cabinet/projects',
}

const GET_PROJECTS: EndpointType = (data: any, fetcher: AxiosInstance, headers) => {
  return fetcher.request({
    method: 'get',
    url: URL.GET_PROJECTS,
    headers,
    params: data,
  })
}

const GET_TASKS: EndpointType = (projectId: any, fetcher: AxiosInstance, headers) => {
  return fetcher.request({
    method: 'get',
    url: `api/cabinet/projects/${projectId}/tasks`,
    headers,
  })
}

const GET_TASK: EndpointType = (data: any, fetcher: AxiosInstance, headers) => {
  return fetcher.request({
    method: 'get',
    url: `api/cabinet/projects/${data.projectId}/tasks/${data.taskId}`,
    headers,
  })
}

const UPDATE_TASK: EndpointType = (
  { projectId, taskId, formData },
  fetcher: AxiosInstance,
  headers,
) => {
  return fetcher.request({
    method: 'post',
    url: `api/cabinet/projects/${projectId}/tasks/${taskId}`,
    data: formData,
    headers,
  })
}

const DELETE_ATTACHMENT: EndpointType = (
  { projectId, taskId, formData },
  fetcher: AxiosInstance,
  headers,
) => {
  return fetcher.request({
    method: 'post',
    url: `api/cabinet/projects/${projectId}/tasks/${taskId}/attachment`,
    data: formData,
    headers,
  })
}

const UPLOAD_ATTACHMENTS: EndpointType = (
  { projectId, taskId, formData },
  fetcher: AxiosInstance,
  headers,
) => {
  return fetcher.request({
    method: 'post',
    url: `api/cabinet/projects/${projectId}/tasks/${taskId}/attachment`,
    data: formData,
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  })
}

const CREATE_COMMENT: EndpointType = (
  { projectId, taskId, formData },
  fetcher: AxiosInstance,
  headers,
) => {
  return fetcher.request({
    method: 'post',
    url: `api/cabinet/projects/${projectId}/tasks/${taskId}/comments`,
    data: formData,
    headers,
  })
}

export const PROJECT_API = {
  GET_TASKS,
  GET_TASK,
  UPDATE_TASK,
  CREATE_COMMENT,
  DELETE_ATTACHMENT,
  UPLOAD_ATTACHMENTS,
  GET_PROJECTS,
}
