import React from 'react'
import classNames from 'classnames/bind'

import { Text, TextType } from 'ui/text'
import { OutsideClickHandler } from 'ui/outside-click-handler'

import { ReactComponent as CloseIcon } from '../../assets/cross.svg'
import { NotificationList } from '../notification-list'

import styles from './widget.module.scss'

const cn = classNames.bind(styles)
type Props = any & React.HTMLProps<HTMLDivElement>

function Widget(props: Props) {
  const { className, onClose, items, ...rest } = props

  return (
    <>
      <div className={cn('kostyl')} />
      <OutsideClickHandler onOutsideClick={onClose}>
        <div {...rest} className={cn('container', className)}>
          <div className={cn('header')}>
            <Text type={TextType.TextMediumBold}>Notifications</Text>
            <button className={cn('close')} onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          <div className={cn('body')}>
            <NotificationList items={items} className={cn('body-wrapper')} />
          </div>
        </div>
      </OutsideClickHandler>
    </>
  )
}

export { Widget }
