import React, { Suspense, useContext } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames/bind'
import { useVisibleState } from 'utils/useVisibleState'
import { AppContext } from 'app-context'
import { Reports } from '../../components/reports'
import { Facts } from '../../components/country-facts'
import { Experts } from '../../components/local-experts'
import { Indicators } from '../../components/indicators'
import { DigitalCompanies } from '../../components/top-digital-companies'
import { Charts } from '../../components/charts'
import { Spinner } from 'ui/spinner'
import { DelayedFallback } from 'ui/delayed'
import styles from './research.module.scss'
import { api } from 'services/api'
import { useHistory } from 'react-router-dom'

const cn = classNames.bind(styles)

function Research() {
  const history = useHistory()
  const { research } = useContext(AppContext)
  const reports: any = research.reports.data

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    api.forgot()
    history.push('/mobile-error')
    return null
  } else {
    const facts: any = research.country_facts.data
    const currentCountry: string = facts.en_name
    const currentCountryImg: string = facts.flag

    let charts: { [key: string]: any } = {
      graphs: research.graphs,
      country_name: currentCountry,
      country_flag: currentCountryImg,
      country_id: facts.id,
    }

    return (
      <Suspense fallback={<Spinner type={'fullscreen'} />}>
        {reports[0] ? (
          <div className={cn('reports')}>
            <div className={cn('report-block-title')}>Reports</div>
            <Reports reports={reports} />
          </div>
        ) : (
          <div className={cn('reports-empty')}>
            <div className={cn('closure-reports')}></div>
          </div>
        )}
        <div className={cn('custom-report-info')}>
          <div className={cn('custom-report-left')}>
            <div className={cn('custom-report-title')}>Order custom report</div>
            <div className={cn('custom-report-description')}>
              You get comprehensive report of market state in beautifuly designed, easy to read PDF
            </div>
            <div
              className={cn('custom-report-button')}
              onClick={() => window.open('https://himrkt.com/custom-report', '_blank')}
            >
              Get started
            </div>
            <div className={cn('custom-report-price')}>Starts from $99</div>
          </div>
          <div className={cn('custom-report-right')}></div>
        </div>
        <div className={cn('country-facts')}>
          <Facts />
        </div>
        <div className={cn('indicators')}>
          <div className={cn('indicator-block-title')}>{currentCountry} Economic Indicators</div>
          <Indicators />
        </div>
        <div className={cn('indicators')}>
          <Charts data={charts} />
        </div>
        <div className={cn('experts')}>
          <div className={cn('experts-block-title')}>
            Local Experts In
            <div className={cn('experts-block-title-group')}>
              <img className={cn('experts-block-title-img')} src={currentCountryImg}></img>
              {currentCountry}
            </div>
          </div>
          <Experts />
        </div>
        <div className={cn('country-manager-info')}>
          <div className={cn('country-manager-left')}>
            <div className={cn('country-manager-title')}>
              You can hire a country manager and save time
            </div>
            <div className={cn('country-manager-button-block')}>
              <div
                className={cn('country-manager-button')}
                onClick={() => window.open('https://himrkt.com/country-manager', '_blank')}
              >
                Get started
              </div>
              <div className={cn('country-manager-price')}>Starts from $299</div>
            </div>
          </div>
          <div className={cn('country-manager-right')}></div>
        </div>
        <div className={cn('digital-companies')}>
          <div className={cn('digital-companies-block-title')}>
            Top Digital Marketing Companies in
            <div className={cn('digital-companies-block-title-group')}>
              <img
                className={cn('digital-companies-block-title-img')}
                src={currentCountryImg}
              ></img>
              {currentCountry}
            </div>
          </div>
          <DigitalCompanies />
        </div>
      </Suspense>
    )
  }
}

const ObservedResearch = observer(Research)

export { ObservedResearch as Research }
