import classNames from 'classnames/bind'
import { useHistory } from 'react-router-dom'

import { LoginForm } from '../../containers/login-form'

import styles from './login.module.scss'

const cn = classNames.bind(styles)

function Login() {
  const history = useHistory()
  return (
    <>
      <div onClick={() => window.open('https://himrkt.com')} className={cn('auth__logo')} />
      <div className={cn('container')}>
        <LoginForm />
      </div>
    </>
  )
}

export { Login }
