import { EndpointType } from 'services/api/types'

const URL = {
  GET_ALL: 'api/cabinet/notifications',
  READ: (id) => `api/cabinet/notifications/${id}`,
  ADD: 'api/cabinet/notifications',
  CLEAR: 'api/cabinet/notifications/clear',
}

const GET_ALL_NOTIFICATIONS: EndpointType = (_, fetcher, headers) =>
  fetcher.request({
    method: 'get',
    url: URL.GET_ALL,
    headers,
  })

const READ_NOTIFICATION: EndpointType = (data, fetcher, headers) =>
  fetcher.request({
    method: 'post',
    url: URL.READ(data.id),
    headers,
  })

const CLEAR_ALL_NOTIFICATIONS: EndpointType = (data, fetcher, headers) =>
  fetcher.request({
    method: 'post',
    url: URL.CLEAR,
    data,
    headers,
  })

const ADD_NOTIFICATION: EndpointType = (data, fetcher, headers) =>
  fetcher.request({
    method: 'post',
    url: URL.ADD,
    data,
    headers,
  })

export const NOTIFICATIONS_API = {
  GET_ALL_NOTIFICATIONS,
  READ_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
  ADD_NOTIFICATION,
}
