import React, { useState, useEffect, useContext } from 'react'
import classNames from 'classnames/bind'
import { Modal, ModalProps } from 'react-responsive-modal'
import { Text, TextType } from 'ui/text'
import { PaymentForm } from 'features/payment-form/components/payment-form'
import { AppContext } from 'app-context'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import styles from './payment-modal.module.scss'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from 'common/constants'
const cn = classNames.bind(styles)
type Props = React.HTMLProps<HTMLDivElement>

function PaymentModal(props: any) {
  const { visible, handleClosePaymentModal, onCreatePaymentMethod } = props
  return (
    <Modal
      open={visible}
      onClose={handleClosePaymentModal}
      center
      showCloseIcon={false}
      classNames={{
        modal: cn('modal'),
      }}
    >
      <Elements stripe={loadStripe(STRIPE_KEY)}>
        <Form onCreatePaymentMethod={onCreatePaymentMethod} />
      </Elements>
    </Modal>
  )
}

function Form(props) {
  const { onCreatePaymentMethod } = props
  const stripe: any = useStripe()
  const elements: any = useElements()
  const [customer, setCustomer]: any = useState({})
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [error, setError]: any = useState(null)
  const [billingDetails, setBillingDetails] = useState({
    name: '',
  })
  const { checkout } = useContext(AppContext)

  useEffect(() => {
    checkout.getCustomer().then((res) => setCustomer(res))
  }, [])
  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (cardComplete) {
      setProcessing(true)
      elements.getElement(CardNumberElement).update({ disabled: true })
      elements.getElement(CardExpiryElement).update({ disabled: true })
      elements.getElement(CardCvcElement).update({ disabled: true })
    }

    const payload = await stripe.confirmCardSetup(customer.client_secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement, { hidePostalCode: true }),
        billing_details: event,
      },
    })

    if (payload.error) {
      setError(payload.error)
      setProcessing(false)
    } else {
      console.info('confirmCardSetup completed')
      console.info(payload)
      onCreatePaymentMethod(payload.setupIntent.payment_method)
    }
  }
  return (
    <>
      <Text type={TextType.HeaderSmall}>Add payment method</Text>
      <Text className={cn('form-description')}> Secure form</Text>
      <PaymentForm
        billingDetails={billingDetails}
        error={error}
        setError={setError}
        processing={processing}
        CardNumberElement={CardNumberElement}
        setCardComplete={setCardComplete}
        CardCvcElement={CardCvcElement}
        handleSubmit={handleSubmit}
        CardExpiryElement={CardExpiryElement}
        buttonTitle={`Add`}
      />
    </>
  )
}

export { PaymentModal }
