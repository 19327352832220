import React from 'react'

import { RootStore } from 'stores/root-store'

const rootStore = new RootStore()
export interface AppContextType extends RootStore {
  openErrorModal: (e?: Error) => void
}

export const AppContext = React.createContext<AppContextType>({
  ...rootStore,
  openErrorModal: () => undefined,
})
