import { Suspense } from 'react'
import classnames from 'classnames/bind'

import { TaskSelect } from '../task-select'
import { Chat } from 'features/chat'
import { Spinner } from 'ui/spinner'

import { CardDetailContainer } from '../../containers/card-detail-container'
import { TaskList } from '../task-list/task-list'

import styles from './tasks.module.scss'
const cn = classnames.bind(styles)

type Props = {
  mobile: string
}

const TasksComponent = (props: any) => {
  const { currentUser, projects, aviableTasks, currentProjectId, handleOpenCardDetail } = props
  const user = currentUser.current
  return (
    <>
      <div className={cn('content')}>
        <div className={cn('content-welcome')}>
          <div>
            Hi,{' '}
            <img
              src={user.avatar}
              alt="avatar"
              width="24"
              height="24"
              className={cn('user-avatar')}
            />{' '}
            {user.name || 'CountryManager'}
          </div>
          <div className={cn('content-select')}>
            <TaskSelect
              currentUser={currentUser}
              aviableTasks={aviableTasks}
              currentProjectId={currentProjectId}
              projects={projects}
            />
          </div>
        </div>
        <Suspense fallback={() => <Spinner />}>
          <TaskList
            currentUser={currentUser}
            aviableTasks={aviableTasks}
            currentProjectId={currentProjectId}
            handleOpenCardDetail={handleOpenCardDetail}
            projects={projects}
          />
        </Suspense>
      </div>
      <div className={cn('chat')}>
        <div className={cn('chat-container')}>
          <Chat projectId={currentProjectId} />
        </div>
      </div>
      <Suspense fallback={() => <Spinner />}>
        <CardDetailContainer />
      </Suspense>
    </>
  )
}

export { TasksComponent }
