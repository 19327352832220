import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { CheckoutForm } from '../containers/checkout'
import { STRIPE_KEY } from 'common/constants'

const Checkout = () => (
  <Elements stripe={loadStripe(STRIPE_KEY)}>
    <CheckoutForm />
  </Elements>
)

export { Checkout }
