import axios, { AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { noop } from 'lodash'

import { RequestHeaderType, EndpointsType } from './types'
import { Endpoints } from './endpoints'

type ExtractAxiosResponse<T> = T extends Promise<AxiosResponse<infer A>> ? A : never

class Api {
  fetcher = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })

  private _apiKey = process.env.REACT_APP_API_KEY || ''

  private _accessToken = ''
  private _refreshToken = ''
  private _deferredRequests: Array<[Function, keyof EndpointsType, any, any]> = []
  private _listeners: Record<string, Array<(...args: any[]) => any>> = {}

  constructor() {
    this._refreshToken = Cookies.get('refresh') || ''
    this._accessToken = Cookies.get('access') || ''
  }

  public on(eventName: string, callback: () => void): void {
    if (!this._listeners[eventName]) {
      this._listeners[eventName] = []
    }

    this._listeners[eventName].push(callback)
  }

  public trigger(eventName: string): void {
    for (const listener of this._listeners[eventName] || []) {
      listener()
    }
  }

  get authHeaders(): RequestHeaderType {
    return {
      'Api-key': this._apiKey,
      Authorization: `Bearer ${this._accessToken}`,
    }
  }

  set accessToken(payload: { token: string; expires: number }) {
    this._accessToken = payload.token
    Cookies.set('access', payload.token, { expires: payload.expires })
  }

  set refreshToken(payload: { token: string; expires: number }) {
    this._refreshToken = payload.token
    Cookies.set('refresh', payload.token, { expires: payload.expires })
  }

  get isAuth(): boolean {
    return !!Cookies.get('refresh')
  }

  exit = (): void => {
    this._deferredRequests = []
    Cookies.remove('refresh')
    Cookies.remove('access')
    this.trigger('exit')
    window.location.assign('https://himrkt.com')
  }

  forgot = (): void => {
    this._deferredRequests = []
    Cookies.remove('refresh')
    Cookies.remove('access')
    this.trigger('forgot')
  }

  async getAccessToken() {
    const result = await Endpoints.REFRESH_TOKEN({ refresh: this._refreshToken }, this.fetcher, {
      'Api-key': this._apiKey,
    }).catch(() => this.exit())

    if (!result) {
      return this.exit()
    }

    this._accessToken = result.data.access

    for (const [resolve, gate, data, headers] of this._deferredRequests) {
      const res = await this.call(gate, data, headers)
      resolve(res)
    }

    this._deferredRequests = []
  }

  async call<K extends keyof EndpointsType, R = ExtractAxiosResponse<ReturnType<EndpointsType[K]>>>(
    gate: K,
    data?: Parameters<EndpointsType[K]>[0],
    headers?: RequestHeaderType,
  ): Promise<R> {
    if (!this._accessToken && this._refreshToken) {
      let resolveLink = noop
      const deferredReq = new Promise<R>((resolve) => (resolveLink = resolve))
      this._deferredRequests.push([resolveLink, gate, data, headers])

      return deferredReq
    }

    try {
      const { data: res } = await Endpoints[gate](data, this.fetcher, {
        ...headers,
        ...this.authHeaders,
      })
      return res
    } catch (err: any) {
      if (gate !== 'LOGIN' && (err.response || {}).status === 401) {
        await this.getAccessToken()

        const { data: res } = await Endpoints[gate](data, this.fetcher, {
          ...headers,
          ...this.authHeaders,
        })
        return res
      }

      throw err
    }
  }
}

export const api = new Api()
