import { useState, useCallback } from 'react'

export function useVisibleState(defaultValue = false) {
  const [visible, setVisible] = useState(defaultValue)

  const open = useCallback(() => {
    setVisible(true)
  }, [])

  const close = useCallback(() => {
    setVisible(false)
  }, [])

  const toggle = useCallback(() => {
    setVisible(!visible)
  }, [visible])

  return {
    visible,
    open,
    close,
    toggle,
  }
}
