import React from 'react'
import classNames from 'classnames/bind'

import { ReactComponent as Spinner } from 'assets/icons/Spinner.svg'

import styles from './spinner.module.scss'

const cn = classNames.bind(styles)

type Props = any

function SpinnerComponent(props: Props) {
  return (
    <div
      className={cn('spinner', {
        fullscreen: props.type === 'fullscreen',
        single: props.type === 'single',
        fullbody: props.type === 'fullbody',
      })}
    >
      <Spinner {...props} />
    </div>
  )
}

export { SpinnerComponent as Spinner }
