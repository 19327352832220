import React, { useCallback, useContext } from 'react'
import classNames from 'classnames/bind'
import { AppContext } from 'app-context'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import styles from './reports.module.scss'
import report_blank_src from '../../assets/report_blank.svg'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { Spinner } from 'ui/spinner'
import './splide-custom.css'
import { createFalse } from 'typescript'

const cn = classNames.bind(styles)

function Reports(data) {
  const reports = data.reports
  return (
    <div className={cn('container')}>
      <Splide
        options={{
          type: 'slider',
          rewind: false,
          padding: { left: 50, right: 50 },
          perPage: 5,
          perMove: 1,
          arrows: true,
          pagination: true,
          wheel: false,
          classes: {
            arrow: 'splide__arrow custom-arrow',
            page: 'splide__pagination__page custom-dot',
            pagination: 'splide__pagination custom-paginate',
          },
        }}
      >
        {reports.map((report: any) => (
          <SplideSlide className={cn('splide__slide_test')} key={Math.random()}>
            <a rel="noreferrer" target="_blank" href={report.file ? report.file.path_src : '#'}>
              <div className={cn('splide__slide__container')}>
                <div className={cn('report-title')}></div>
                <div className={cn('report-body')}>{report.title}</div>
                <div className={cn('report-year')}>{report.year}</div>
              </div>
            </a>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  )
}

export { Reports }
