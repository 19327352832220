import React, { useContext } from 'react'
import classNames from 'classnames/bind'
import { useHistory } from 'react-router'
import format from 'date-fns/format'
import { AppContext } from 'app-context'

import { Text, TextType } from 'ui/text'

import { ReactComponent as ZoomLogo } from '../../assets/zoom-logo.svg'

import styles from './notification.module.scss'
import { Access } from 'common/types'

const cn = classNames.bind(styles)
type Props = React.HTMLProps<HTMLDivElement>

function Sender({ user }) {
  if (!user?.avatar) {
    return null
  }

  return (
    <div className={cn('sender')}>
      <img
        className={cn('sender__avatar')}
        src={user.avatar}
        alt={user?.name ?? 'Product Manager'}
      />
      <Text type={TextType.TextSmall} className={cn('sender__name')}>
        {user?.name ?? 'Product Manager'}
      </Text>
    </div>
  )
}

function DefaultNotification(props) {
  return (
    <div className={cn('container')}>
      <Sender user={props.additional_fields?.sender} />
      <Text type={TextType.TextMediumBold} className={cn('message')}>
        {props.message || props.content}
      </Text>
    </div>
  )
}

function TaskNotification(props) {
  const history = useHistory()

  const { currentUser } = useContext(AppContext)
  const clickable = React.useMemo(() => {
    return !!props.additional_fields?.task_id
  }, [props.additional_fields])

  const date = React.useMemo(() => {
    if (!props.created_at) {
      return ''
    }

    const createdAt = new Date(props.created_at)
    return format(createdAt, 'dd MMM . HH:mm').split('.').join('at')
  }, [props.created_at])

  const onClick = React.useCallback(() => {
    if (!clickable) {
      return false
    }

    console.info(props.additional_fields)
    if (currentUser.current.role === Access.CUSTOMERDASHBOARD) {
      history.push(
        `/tasks?projectId=${props?.additional_fields.project_id}&taskId=${props?.additional_fields?.task_id}`,
      )
    }

    if (currentUser.current.role === Access.COUNTRY_MANAGER) {
      history.push(
        `/clients/${props?.additional_fields?.company_id}?projectId=${props?.additional_fields.project_id}&taskId=${props?.additional_fields?.task_id}`,
      )
    }
  }, [history, clickable])

  return (
    <div className={cn('task')}>
      <div className={cn('task__header')}>
        <Text type={TextType.TextSmall} className={cn('task__header-date')}>
          {date}
          {Boolean(props.additional_fields?.sender) ? ' by ' : ''}
        </Text>
        <Sender user={props.additional_fields?.sender} />
      </div>
      <Text
        type={TextType.TextMediumBold}
        className={cn('message', { _clickable: clickable })}
        onClick={onClick}
      >
        {props.content}
      </Text>
    </div>
  )
}

function ZoomNotification(props) {
  return (
    <div className={cn('zoom')}>
      <ZoomLogo className={cn('zoom__logo')} />
      <Text type={TextType.TextMedium}>Manager invites you to Zoom conference</Text>

      {!!props.link && (
        <div className={cn('zoom__actions')}>
          <a href={props.link} target={'_blank'} className={cn('zoom__accept')} rel="noreferrer">
            Accept
          </a>
          <button className={cn('zoom__decline')}>Decline</button>
        </div>
      )}
    </div>
  )
}

function Notification(props: Props) {
  switch (props.type) {
    case 'zoom':
      return <ZoomNotification {...props} />
    case 'task':
    case 'default':
    default:
      return <TaskNotification {...props} />
  }
}

export { Notification }
