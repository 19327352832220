import { flow, observable, action } from 'mobx'

import { RootStore } from 'stores/root-store'
import { api, AsyncState } from 'services/api'
import { makeResource, Resource } from 'utils/make-resource'

import {
  AvailableCountriesErrorResponse,
  AvailableCountriesResponse,
  AvailableCountriesSuccessResponse,
  CountriesWithPlan,
  AuthRequest,
  AuthResponse,
  ErrorAuthResponse,
  SaveCompanyRequest,
  SaveCompanyResponse,
  SuccessAuthResponse,
} from '../types'

export class AuthStore {
  @observable
  availableCountriesLoadingState: AsyncState = AsyncState.IDLE

  @observable
  availableCountries: Resource<CountriesWithPlan>

  constructor(private rootStore: RootStore) {
    this.createResources()
  }

  private async authRequest(payload: AuthRequest, gate: 'LOGIN' | 'REGISTER') {
    try {
      const result: SuccessAuthResponse = await api.call(gate, payload)

      api.accessToken = {
        token: result.access_token,
        expires: result.expires_in,
      }
      api.refreshToken = {
        token: result.refresh_token,
        expires: result.expires_in,
      }

      return result
    } catch (err: any) {
      return err.response.data as ErrorAuthResponse
    }
  }

  login(payload: AuthRequest): Promise<AuthResponse> {
    return this.authRequest(payload, 'LOGIN')
  }

  register(payload: AuthRequest): Promise<AuthResponse> {
    return this.authRequest(payload, 'REGISTER')
  }

  async saveCompany(payload: SaveCompanyRequest): Promise<SaveCompanyResponse> {
    try {
      const result = await api.call('SAVE_COMPANY', payload)
      return result
    } catch (err: any) {
      return err.response.data as SaveCompanyResponse
    }
  }

  @action
  async loadAvailableCountries() {
    const raw: AvailableCountriesResponse = await api.call('AVAILABLE_COUNTRIES')

    if (raw.success) {
      return raw as AvailableCountriesSuccessResponse
    }

    throw new Error((raw as AvailableCountriesErrorResponse).message)
  }

  fetchAvailableCountriesResource = flow(function* (this: AuthStore) {
    const response = yield this.loadAvailableCountries()
    return response
  })

  createResources(): void {
    this.availableCountries = makeResource<CountriesWithPlan, []>(
      async () => await this.fetchAvailableCountriesResource(),
    )
  }
}
