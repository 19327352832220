export type AuthRequest = {
  email: string
  password: string
  recaptcha: any
}

export type SuccessAuthResponse = {
  success: boolean
  access_token: string
  refresh_token: string
  token_type: string
  expires_in: number
  role_id: number
}
export type ErrorAuthResponse = {
  success: boolean
  message: string
}

export type AuthResponse = any | ErrorAuthResponse

export enum RegistrationSteps {
  IDENTIFY = 'identify',
  COMPANY = 'company',
  COUNTRIES = 'countries',
  CHECKOUT = 'checkout',
  PAYMENT_ERROR = 'payment-error',
}

export type SaveCompanyRequest = {
  name: string
}

export type SaveCompanyResponse = {
  success: boolean
  message: string
}

type Country = {
  id: number
  en_name: string
  ru_name: string | null
  flag: string
  alpha3code: string
  lat: string
  lng: string
  price: number
}

type Plan = {
  product_id: string
  plan_id: string
}

export type CountriesWithPlan = Array<Country & Plan>

export type AvailableCountriesSuccessResponse = {
  success: boolean
  data: {
    countries: CountriesWithPlan
    worldwide_access: Plan
  }
}

export type AvailableCountriesErrorResponse = {
  success: boolean
  message: string
}

export type AvailableCountriesResponse =
  | AvailableCountriesSuccessResponse
  | AvailableCountriesErrorResponse
