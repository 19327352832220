import React from 'react'
import classNames from 'classnames/bind'

import { Text, TextType } from 'ui/text'

import styles from './what-clients-say.module.scss'
const cn = classNames.bind(styles)

type Props = {
  headerClassName?: string
}

export function WhatClientsSay({ headerClassName }: Props) {
  return (
    <>
      <div className={cn('wrapper')}>
        <Text type={TextType.HeaderXLarge} className={cn('review-title', headerClassName)}>
          What clients say
        </Text>
      </div>

      <div className={cn('wrapper', '_reviews')}>
        <div className={cn('review')}>
          <div className={cn('review-block')}>
            <div className={cn('review-avatar')}> </div>
            <div className={cn('review-text')}>
              <Text type={TextType.HeaderXSmall} className={cn('review-name')}>
                Arlene McCoy
              </Text>
              <Text type={TextType.TextLarge} className={cn('review-description')}>
                Live Simple Pte. Ltd.
              </Text>
            </div>
          </div>
          <Text type={TextType.TextXLarge} className={cn('review-comment')}>
            I like that I can just post my questions to chat any time of the day, quite unlike
            having to remember to call within business hours only.
          </Text>
        </div>
        <div className={cn('review')}>
          <div className={cn('review-block')}>
            <div className={cn('review-avatar', '_russel')}> </div>
            <div className={cn('review-text')}>
              <Text type={TextType.HeaderXSmall} className={cn('review-name')}>
                Dianne Russell
              </Text>
              <Text type={TextType.TextLarge} className={cn('review-description')}>
                Simplicount Pte. Ltd.
              </Text>
            </div>
          </div>
          <Text type={TextType.TextXLarge} className={cn('review-comment')}>
            The interface is cool and friendly. Makes the first steps towards entrepreneurship so
            much easier.
          </Text>
        </div>
        <div className={cn('review')}>
          <div className={cn('review-block')}>
            <div className={cn('review-avatar', '_darrel')}> </div>
            <div className={cn('review-text')}>
              <Text type={TextType.HeaderXSmall} className={cn('review-name')}>
                Darrell Steward
              </Text>
              <Text type={TextType.TextLarge} className={cn('review-description')}>
                Evans Media Pte. Ltd.
              </Text>
            </div>
          </div>
          <Text type={TextType.TextXLarge} className={cn('review-comment')}>
            Simple, easy and super handy! All I need for product launch just found on Hi Market
          </Text>
        </div>
      </div>
    </>
  )
}
