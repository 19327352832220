import React from 'react'
import classNames from 'classnames/bind'
import { useHistory } from 'react-router-dom'
import { Text, TextType } from 'ui/text'

import { AuthForm } from '../auth-form'

import styles from './register-form.module.scss'
import { api } from 'services/api'

const cn = classNames.bind(styles)

type Props = React.ComponentProps<typeof AuthForm>

function Identify({ onSubmit }: Props) {
  const history = useHistory()
  const initialValues = React.useMemo(() => {
    const email = window.history?.state?.state?.email
    if (email) {
      return { email }
    }

    return {}
  }, [])

  return (
    <>
      <div className={cn('auth__logo')} onClick={() => window.open('https://himrkt.com')} />
      <div className={cn('auth__block', '_auth')}>
        <Text className={cn('auth__step')} type={TextType.TextSmall}>
          1 step to 4
        </Text>
        <Text className={cn('auth__title')} type={TextType.HeaderLarge}>
          Sign up
        </Text>
        <Text className={cn('auth__description')} type={TextType.TextMedium}>
          We will use this information only in your <br /> private account
        </Text>
        <AuthForm initialValues={initialValues} onSubmit={onSubmit} />
        <Text
          onClick={() => history.push('/auth')}
          className={cn('link')}
          type={TextType.TextSmall}
        >
          Log in
        </Text>
      </div>
    </>
  )
}

export { Identify }
