import React from 'react'
import classNames from 'classnames/bind'

import { Text, TextType } from 'ui/text'

import { Notification } from '../notification'

import styles from './notification-list.module.scss'

const cn = classNames.bind(styles)
type Props = any & React.HTMLProps<HTMLDivElement>

function NotificationList(props: Props) {
  const { className, items, ...rest } = props

  return (
    <div {...rest} className={cn('container', className)}>
      {!!items.length ? (
        items.map((current) => <Notification {...current} key={current.id} />)
      ) : (
        <Text type={TextType.TextMedium}>Has no notifications yet.</Text>
      )}
    </div>
  )
}

export { NotificationList }
