import React from 'react'

import { HeaderDesktop } from './header-desktop'
import { HeaderMobile } from './header-mobile'

type Props = {
  mobile?: string
} & React.HTMLProps<HTMLDivElement>

const HeaderLayout = (props: Props) => {
  const { mobile } = props
  if (mobile) {
    return <HeaderMobile />
  }

  return <HeaderDesktop />
}

export { HeaderLayout as Header }
