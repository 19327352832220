import React, { useContext, memo, forwardRef } from 'react'

export type Slice<P, CTX, InjectedProps> = (ctx: CTX, props: P) => InjectedProps

function withCtxSlice<CTX>(ctx: React.Context<CTX>) {
  return function <P, InjectedProps>(
    Comp: React.ComponentType<P>,
    slice: Slice<P, CTX, InjectedProps>,
  ): React.ComponentType<Omit<P, keyof InjectedProps>> {
    const MemoComp = memo(Comp)

    function Wrapper(props, ref): JSX.Element {
      const context = useContext(ctx)
      return <MemoComp ref={ref} {...props} {...slice(context, props)} />
    }
    Wrapper.displayName = `withCtxSlice(${Comp.displayName || Comp.name})`

    return memo(forwardRef(Wrapper))
  }
}

export { withCtxSlice }
