import { useEffect } from 'react'
import classNames from 'classnames/bind'
import { chatResource } from 'services/chat'

import styles from './chat.module.scss'

const cn = classNames.bind(styles)

type Props = {
  projectId: number
}
export function Chat({ projectId }: Props) {
  const src = chatResource.read(projectId)

  useEffect(() => {
    chatResource.reset()
  }, [projectId])

  return <iframe src={src} className={cn('iframe')} id="chat-id"></iframe>
}
