import React from 'react'
import classNames from 'classnames/bind'
import { Form } from 'react-final-form'

import { FinalFormField } from 'ui/final-form-field'
import { Button } from 'ui/button'
import { FormError } from 'ui/form-error'

import styles from './payment-form.module.scss'

const cn = classNames.bind(styles)
type Props = React.HTMLProps<HTMLDivElement>

function PaymentForm(props: any) {
  const {
    billingDetails,
    handleSubmit,
    error,
    setError,
    processing,
    CardNumberElement,
    setCardComplete,
    CardCvcElement,
    CardExpiryElement,
    buttonTitle,
  } = props

  return (
    <Form
      initialValues={billingDetails}
      onSubmit={handleSubmit}
      render={({ hasSubmitErrors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {error && <FormError message={error.message} close={() => setError(null)} />}

          <FinalFormField
            disabled={processing}
            required
            name={'name'}
            type={'name'}
            label="Name"
            placeholder="Name"
          />
          <div>
            <div className={cn('card-label')}>Card number</div>
            <CardNumberElement
              onChange={(e) => {
                setError(e.error)
                setCardComplete(e.complete)
              }}
              className={cn('card-input')}
            />
          </div>

          <div className={cn('card-items')}>
            <div className={cn('card-item')}>
              <div className={cn('card-label')}>CVC</div>
              <CardCvcElement className={cn('card-input')} />
            </div>
            <div className={cn('card-item')}>
              <div className={cn('card-label')}>Expiration</div>
              <CardExpiryElement className={cn('card-input')} />
            </div>
          </div>

          <Button className={cn('button')} type="submit" disabled={processing}>
            {buttonTitle}
          </Button>
        </form>
      )}
    ></Form>
  )
}

export { PaymentForm }
