import { createElement, Fragment } from 'react'

type Props = {
  match: boolean
} & React.HTMLProps<HTMLDivElement>

const Condition = (props: Props) => {
  const { match, children } = props
  if (match) {
    return createElement(Fragment, {}, children)
  }

  return null
}

export { Condition }
