import React, { useCallback, useState, useContext, Suspense, useEffect } from 'react'
import classNames from 'classnames/bind'
import { AppContext } from 'app-context'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import styles from './charts.module.scss'
import { Spinner } from 'ui/spinner'
import { toJS, action } from 'mobx'
import { setConstantValue } from 'typescript'
import { Graphs } from './graphs'
import { RootStore } from 'stores/root-store'
import { api, AsyncState } from 'services/api'
import { observer, useLocalStore } from 'mobx-react'
import { trackDerivedFunction } from 'mobx/dist/internal'
import { AnyAaaaRecord } from 'dns'

const cn = classNames.bind(styles)

function Charts(data) {
  const store = useContext(AppContext)
  const charts: any = toJS(data)
  const menu: any = Object.values(store.research.menu.data)
  const flag: any = toJS(charts.data.country_flag)
  const country_name: any = toJS(charts.data.country_name)
  const country_id: any = toJS(charts.data.country_id)

  const payload: { [key: string]: any } = {
    id: country_id,
    type: menu[0],
  }

  const [graphs, setGraphs] = useState({})
  const [isLoaded, setLoad] = useState(true)

  function rerenderGraphs(option, e) {
    payload.id = country_id
    payload.type = option
    payload.flag = flag
    payload.country_name = country_name
    payload.title = payload.type
    const graphs_data: { [key: string]: any } = {
      data: store.research.getGraphs(payload),
    }
    setTimeout(() => {
      setLoad(false)
      let elements = Array.from(document.getElementsByClassName('menu-active'))
      elements.forEach((element) => {
        element.classList.remove('menu-active')
      })
      const el = e.target
      el.classList.add('menu-active')
    }, 2 * 1000)

    setGraphs(graphs_data)
    return graphs_data
  }
  useEffect(
    () => {
      console.log(isLoaded)
      let timer1 = setTimeout(() => setLoad(false), 2 * 1000)
      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1)
      }
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [],
  )

  return (
    <div className={cn('container')}>
      {isLoaded ? (
        <Spinner type={'single'} />
      ) : (
        <div className={cn('charts-body')}>
          <div className={cn('charts-menu')}>
            {menu.map((option: any, index: any) => {
              return (
                <Text
                  key={index}
                  className={cn('', {
                    'menu-title': true,
                  })}
                  onClick={(e) => [rerenderGraphs(option, e), setLoad(true)]}
                >
                  {option}
                </Text>
              )
            })}
          </div>
          <Graphs graphs={graphs} />
        </div>
      )}
    </div>
  )
}

export { Charts }
