import { flow, observable, action, makeAutoObservable } from 'mobx'

import { RootStore } from 'stores/root-store'
import { api } from 'services/api'

export class SettingStore {
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this)
  }

  async updatePassword(payload: any): Promise<any> {
    try {
      const result = await api.call('UPDATE_PROFILE', payload)
      return result
    } catch (err: any) {
      return err.response.data
    }
  }
  async updatePaymentMethod(payload: any): Promise<any> {
    try {
      const result: any = await api.call('UPDATE_PAYMENT_METHOD', payload)

      if (result.success) {
        this.rootStore.currentUser.fetch()
      }
    } catch (err: any) {
      return err
    }
  }
  async deletePaymentMethod(payload: any): Promise<any> {
    try {
      const result = await api.call('DELETE_PAYMENT_METHOD', payload)
      if (result.success) {
        this.rootStore.currentUser.fetch()
      }
    } catch (err: any) {
      return err
    }
  }

  async createPaymentMethod(payload: any): Promise<any> {
    try {
      const result = await api.call('CREATE_PAYMENT_METHOD', payload)
      if (result.success) {
        this.rootStore.currentUser.fetch()
        return result
      }
    } catch (err: any) {
      return err
    }
  }
}
