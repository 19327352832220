import { RenderMainRoutes } from 'components/routes'
import { Main } from 'features/static-pages/pages/main'
import {
  CountryManager,
  Partnership,
  PrivacyPolicy,
  TermsOfUse,
  MobileError,
} from 'features/static-pages'
import { Tasks } from 'features/tasks/containers/tasks'
import { Clients } from 'features/clients/containers/clients'
import { ClientDetail } from 'features/clients/containers/client-detail'
import { Settings } from 'features/settings/containers/settings'
import { RegisterForm as Registration } from 'features/auth/containers/register-form'
import { Login, Me } from 'features/auth'

import { CustomReport } from './features/static-pages/pages/custom-report'
import { Access } from './common/types'

import { Research } from 'features/research/containers/research'

enum KEYS {
  INDEX = 'INDEX',
  MAIN = 'MAIN',
  ME = 'ME',
  CLIENTS = 'CLIENTS',
  CLIENTS_DETAIL = 'CLIENTS_DETAIL',
  COUNTRY_MANAGER = 'COUNTRY_MANAGER',
  PARTNERSHIP = 'PARTNERSHIP',
  TASKS = 'TASKS',
  TASK = 'TASK',
  REGISTRATION = 'REGISTRATION',
  AUTH = 'AUTH',
  SETTINGS = 'SETTINGS',
  PRE_LAUNCH = 'PRE_LAUNCH',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_OF_USE = 'TERMS_OF_USE',
  CUSTOM_REPORT = 'CUSTOM_REPORT',
  RESEARCH = 'RESEARCH',
  MOBILE_ERROR = 'MOBILE_ERROR',
}

const MainRoutes = [
  {
    path: '/settings',
    exact: true,
    key: KEYS.SETTINGS,
    access: Access.CUSTOMER,
    component: Settings,
    type: 'auth',
  },

  {
    path: '/research',
    exact: true,
    key: KEYS.RESEARCH,
    access: Access.CUSTOMER,
    component: Research,
    type: 'auth',
  },

  {
    path: '/clients',
    exact: true,
    key: KEYS.CLIENTS,
    access: Access.COUNTRY_MANAGER,
    component: Clients,
    type: 'auth',
  },

  {
    path: '/clients/:companyId',
    exact: true,
    key: KEYS.CLIENTS_DETAIL,
    access: Access.COUNTRY_MANAGER,
    component: ClientDetail,
    type: 'auth',
  },
  {
    path: '/tasks',
    exact: true,
    key: KEYS.TASKS,
    component: Tasks,
    access: Access.CUSTOMERDASHBOARD,
    type: 'auth',
  },
]

export const routes = [
  {
    path: '/',
    exact: true,
    key: KEYS.MAIN,
    component: Main,
  },
  {
    path: '/me',
    exact: true,
    key: KEYS.ME,
    component: Me,
    type: 'auth',
  },
  {
    path: '/auth',
    exact: true,
    key: KEYS.AUTH,
    component: Login,
  },
  {
    path: '/registration/:stepId',
    exact: true,
    key: KEYS.REGISTRATION,
    component: Registration,
  },
  {
    path: '/registration/:stepId',
    exact: true,
    key: KEYS.REGISTRATION,
    component: Registration,
  },
  {
    path: '/country-manager',
    exact: true,
    key: KEYS.COUNTRY_MANAGER,
    component: CountryManager,
  },
  {
    path: '/mobile-error',
    exact: true,
    key: KEYS.MOBILE_ERROR,
    component: MobileError,
  },
  {
    path: '/custom-report',
    exact: true,
    key: KEYS.CUSTOM_REPORT,
    component: CustomReport,
  },
  {
    path: '/partnership',
    exact: true,
    key: KEYS.PARTNERSHIP,
    component: Partnership,
  },

  {
    path: '/privacy-policy',
    exact: true,
    key: KEYS.PRIVACY_POLICY,
    component: PrivacyPolicy,
  },

  {
    path: '/terms-of-use',
    exact: true,
    key: KEYS.TERMS_OF_USE,
    component: TermsOfUse,
  },

  {
    path: '/',
    key: KEYS.INDEX,
    component: RenderMainRoutes,
    routes: MainRoutes,
  },
]
