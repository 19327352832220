import React from 'react'

import { withBreakpointsSlice } from 'ui/breakpoints-provider'
import { Footer } from 'components/footer'

import { Header } from '../header'

type Props = {
  mobile?: string
} & React.HTMLProps<HTMLDivElement>

const Layout = (props: Props) => {
  const { children, ...rest } = props

  return (
    <>
      <Header {...rest} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

const LayoutWithBreakpoints = withBreakpointsSlice(Layout, ({ mobile }) => ({ mobile }))

export { LayoutWithBreakpoints as Layout }
