import React from 'react'
import classNames from 'classnames/bind'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'

import { api } from 'services/api'
import { Button } from 'ui/button'
import { Text, TextType } from 'ui/text'
import { FinalFormField } from 'ui/final-form-field'

import { FormError } from '../../../../ui/form-error'
import { useFormError } from '../../utils/useFormError'
import { SaveCompanyRequest, SaveCompanyResponse } from '../../types'

import styles from './register-form.module.scss'

const cn = classNames.bind(styles)

type Props = {
  onSubmit: (e: SaveCompanyRequest) => Promise<SaveCompanyResponse>
}

function Company({ onSubmit }: Props) {
  const withFormError = useFormError(onSubmit)
  const history = useHistory()

  if (!api.isAuth) {
    history.push('/auth')
  }

  return (
    <>
      <div className={cn('auth__logo')} onClick={() => window.open('https://himrkt.com')} />

      <div className={cn('auth__block', '_company')}>
        <Text className={cn('auth_step')} type={TextType.TextSmall}>
          2 step to 4
        </Text>
        <Text className={cn('auth__title')} type={TextType.HeaderLarge}>
          Tell us about your company
        </Text>
        <Text className={cn('auth__description')} type={TextType.TextMedium}>
          We will use this information only in your private account
        </Text>
        <Form
          onSubmit={withFormError.handlers.submit}
          render={({ handleSubmit }) => (
            <form className={cn('auth__form')} onSubmit={handleSubmit}>
              {withFormError.message && (
                <FormError message={withFormError.message} close={withFormError.handlers.close} />
              )}
              <FinalFormField name={'name'} className={cn('input')} label="Company name" />
              <div className={cn('button__wrapper')}>
                <Button disabled={withFormError.submitting} className={cn('button')} type="submit">
                  Continue
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </>
  )
}

export { Company }
