import React from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Text, TextType } from 'ui/text'
import { AppContext } from 'app-context'
import { Access } from 'common/types'

import classNames from 'classnames/bind'
import { AuthForm } from '../../components/auth-form'
import styles from '../../components/auth-form/auth-form.module.scss'
import { api } from 'services/api'

const cn = classNames.bind(styles)

function LoginFormContainer() {
  const { auth } = React.useContext(AppContext)
  const history = useHistory()
  if (api.isAuth) {
    history.push('/me')
  }

  const onSubmit = React.useCallback(
    async (evt) => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        api.forgot()
        history.push('/mobile-error')
      } else {
        const result = await auth.login(evt)
        if (result.success) {
          history.push('/me')
        }

        return result
      }
    },
    [auth.login],
  )

  return (
    <>
      <Text className={cn('login-title')} type={TextType.HeaderLarge}>
        Log in
      </Text>
      <AuthForm onSubmit={onSubmit} />
      {/*<Text className={cn('login-forgot')} type={TextType.TextSmall}>*/}
      {/*  Forgot password?*/}
      {/*</Text>*/}
    </>
  )
}

const ObservedLoginFormContainer = observer(LoginFormContainer)

export { ObservedLoginFormContainer as LoginForm }
