import React from 'react'
import classnames from 'classnames/bind'

import { TextType } from './types'
import styles from './text.module.scss'

const cn = classnames.bind(styles)

type Props = {
  type?: TextType
} & React.HTMLProps<HTMLDivElement>

function Text(props: Props) {
  const { className, type, children, ...rest } = props

  return (
    <div {...rest} className={cn('text', type, className)}>
      {children}
    </div>
  )
}

const MemoText = React.memo(Text)

export { MemoText as Text }
