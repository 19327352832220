import { observable } from 'mobx'

import { AsyncState } from 'services/api/types'

export class Resource<T, ARGS extends Array<any> = []> {
  @observable
  state = AsyncState.IDLE

  error: any

  promise?: Promise<T>

  constructor(private fn: (...args: ARGS) => Promise<T>) {}

  result?: T

  reset = (): void => {
    this.promise = undefined
    this.error = undefined
    this.result = undefined
    this.state = AsyncState.IDLE
  }

  read = (...args: ARGS): T => {
    switch (this.state) {
      case AsyncState.IDLE: {
        this.state = AsyncState.PENDING

        this.promise = this.fn(...args)
          .then((result) => {
            this.state = AsyncState.RESOLVED
            this.result = result
            return this.result
          })
          .catch((error) => {
            this.state = AsyncState.REJECTED
            this.error = error
          }) as Promise<T>
        throw this.promise
      }
      case AsyncState.PENDING: {
        throw this.promise
      }
      case AsyncState.RESOLVED: {
        return this.result as T
      }
      case AsyncState.REJECTED: {
        throw this.error
      }
    }
  }
}

export const makeResource = <T, ARGS extends Array<any>>(fn: (...args: ARGS) => Promise<T>) => {
  return new Resource<T, ARGS>(fn)
}
