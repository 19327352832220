import React from 'react'
import classNames from 'classnames/bind'
import RS from 'react-select'

import styles from './select.module.scss'

const cn = classNames.bind(styles)
type Props = {
  options?: any[]
  placeholder?: string
  isSearchable?: boolean
} & React.HTMLProps<HTMLDivElement>

function Select(props: any) {
  const { placeholder, options, isSearchable, getOptionLabel, getOptionValue, handleChange } = props

  return (
    <RS
      className={cn('select')}
      classNamePrefix={'project-select'}
      components={{ IndicatorSeparator: () => null }}
      options={options}
      placeholder={placeholder}
      isSearchable={isSearchable}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onChange={handleChange}
      {...props}
    />
  )
}

export { Select }
