import React from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames/bind'

import { Text, TextType } from 'ui/text'
import { FinalFormField } from 'ui/final-form-field'
import { api } from 'services/api'

import { Layout } from '../../components/layout/layout'
import HowIt1 from '../../assets/how-it-1.png'
import HowIt2 from '../../assets/how-it-2.png'
import HowIt3 from '../../assets/how-it-3.png'

import styles from './partnership.module.scss'

const cn = classnames.bind(styles)

function Partnership() {
  const history = useHistory()
  const [isFormSubmitted, setFormSubmitted] = React.useState(false)

  const onValidate = React.useCallback((values) => {
    if (values.email) {
      return { Email: 'Field is required' }
    }
  }, [])

  const onSubmit = React.useCallback((values) => {
    api.call('FEEDBACK', values).finally(() => setFormSubmitted(true))
  }, [])

  return (
    <Layout>
      <div className={cn('partnership')}>
        <div className={cn('wrapper')}>
          <div className={cn('main')}>
            <div className={cn('main__description')}>
              <Text type={TextType.HeaderXXLarge} className={cn('main__description-title')}>
                How to become our partner
              </Text>
              <Text type={TextType.TextXXLarge} className={cn('main__description-sub-title')}>
                A work environment to the high levels of the high levels of quality, teamwork,
                competitors operating at world-class compete in the company will demand focused
                improving quality.
              </Text>
              <Text type={TextType.TextXLarge}>
                As times have recognize that effective advantage, and focused improvements that
                effective leadership and practiced by world-class company&apos;s compete in the
                importance - in quality, teamwork, company. Human resource of compete in
                today&apos;s market.
              </Text>
            </div>
          </div>
          <div className={cn('how')}>
            <Text type={TextType.HeaderLarge}>How it works</Text>
            <div className={cn('how__examples')}>
              <div className={cn('how__example')}>
                <div className={cn('how__example-image')}>
                  <img src={HowIt1} alt={''} />
                </div>
                <div className={cn('how__example-description')}>
                  <Text type={TextType.TextXLarge}>
                    Business client chooses countries where they want to launch a product
                  </Text>
                </div>
              </div>
              <div className={cn('how__example')}>
                <div className={cn('how__example-image')}>
                  <img src={HowIt2} alt={''} />
                </div>
                <div className={cn('how__example-description')}>
                  <Text type={TextType.TextXLarge}>
                    The platform assigns a country manager who will lead a client through all steps
                    and tasks
                  </Text>
                </div>
              </div>
              <div className={cn('how__example')}>
                <div className={cn('how__example-image')}>
                  <img src={HowIt3} alt={''} />
                </div>
                <div className={cn('how__example-description')}>
                  <Text type={TextType.TextXLarge}>
                    Partners will get clients directly from the platform
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className={cn('what')}>
            <Text type={TextType.HeaderLarge}>What you get</Text>
            <Text type={TextType.TextXXLarge} className={cn('what__description')}>
              At this stage we are looking for strategic partners (marketing and digital agencies)
              interested to get new business clients through the platform.
            </Text>
          </div>

          <div className={cn('how-much')}>
            <Text type={TextType.HeaderLarge}>How much we ask</Text>
            <Text type={TextType.TextXXLarge} className={cn('how-much__description')}>
              The partnership is without any registration charges or so. The business model is CPA
              based (5%): we get commission from payments for completed assignments.
            </Text>
            <Text type={TextType.TextXLargeBold}>No cure, no pay!</Text>
          </div>

          <div className={cn('how-to')}>
            <Text type={TextType.HeaderLarge}>How to become our partner</Text>

            <ol className={cn('how-to__list')}>
              <li>
                <Text type={TextType.TextXLarge}>Let us know by sending email;</Text>
              </li>
              <li>
                <Text type={TextType.TextXLarge}>
                  Send us price ranges for services you provide;
                </Text>
              </li>
              <li>
                <Text type={TextType.TextXLarge}>We create an account and send all details.</Text>
              </li>
            </ol>

            <Text type={TextType.TextLarge}>
              We hate spam! So don’t expect any promo emails from us :) And we would be happy to
              know you, so let&apos;s have a call!
            </Text>

            {isFormSubmitted ? (
              <div className={cn('form', 'form_submitted')}>
                <Text type={TextType.TextIcon}>🎉</Text>
                <Text type={TextType.HeaderLarge}>Thank you!</Text>
              </div>
            ) : (
              <Form
                onSubmit={onSubmit}
                onValidate={onValidate}
                render={({ handleSubmit, form, submitting }) => (
                  <form onSubmit={handleSubmit} className={cn('form')}>
                    <Text type={TextType.TextMediumBold} className={cn('form__label')}>
                      Name
                    </Text>
                    <FinalFormField name={'name'} placeholder={'Name'} />
                    <Text type={TextType.TextMediumBold} className={cn('form__label')}>
                      Email<sup>*</sup>
                    </Text>
                    <FinalFormField name={'email'} type={'email'} placeholder={'Email'} required />
                    <button type={'submit'} disabled={submitting} className={cn('form__submit')}>
                      <Text type={TextType.TextLargeBold}>Send</Text>
                    </button>
                  </form>
                )}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export { Partnership }
