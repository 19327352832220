import React from 'react'
import classNames from 'classnames/bind'

import { Text, TextType } from 'ui/text'

import { ReactComponent as CloseIcon } from './assets/Cross.svg'
import styles from './form-error.module.scss'

const cn = classNames.bind(styles)
type Props = {
  message: string
  close: () => void
} & React.HTMLProps<HTMLDivElement>

function FormError(props: Props) {
  const { className, message, close } = props

  return (
    <div className={cn('container', className)}>
      <CloseIcon className={cn('icon')} onClick={close} />
      <Text type={TextType.TextSmall} className={cn('text')}>
        {message}
      </Text>
    </div>
  )
}

export { FormError }
