import React from 'react'
import classnames from 'classnames/bind'

import { Layout } from '../../components/layout/layout'

import styles from './privacy-policy.module.scss'

const cn = classnames.bind(styles)

export function PrivacyPolicy() {
  return (
    <Layout>
      <div className={cn('wrapper', 'text-page')}>
        <h1>Privacy Policy Oh Hi Market Website</h1>
        <p>
          Your privacy is important to Oh Hi Market (OHM). That is why we do everything we can to
          handle and protect your personal data with care. Below we explain how we do this and we
          ask you for your permission by placing a tick for approval.
        </p>

        <h2>General information</h2>
        <p>
          OHM is a service and trade name of SNL Technologies LLC, located at Ottho Heldringstraat 3
          (1066 AZ) in Amsterdam, The Netherlands. OHM is the registered trademark.
        </p>

        <h2>Cookies Website</h2>
        <p>
          The OHM website uses cookies to provide you with the best possible service as a visitor to
          the website. Cookies are small text files that are stored on your computer by the browser
          to increase the ease of use of the website. This information does not contain name or
          address details or other personal details. You can set your browser so that you do not
          receive cookies during your visit to our website. In that case, however, you may not be
          able to use all the features of our website or that you will not have access to parts of
          our website. Of course, data will not be shared with third parties without your
          permission.
        </p>
        <p>
          We use the information we receive from cookies for the following purposes:
          <ul>
            <li>
              to be able to inform you in a better and more personal way about our services and
              products
            </li>
            <li>for making analyzes for research and statistics</li>
            <li>to improve the website</li>
            <li>for security purposes</li>
          </ul>
        </p>
        <p>
          The OHM Website may also display advertisements from Google and other advertisers who may
          use cookies and to which the privacy policies of those third parties apply.
        </p>

        <h2>What we do with your data</h2>
        <p>
          If you register for our service, we will use the information we obtain from you, such as
          the account information (including name, e-mail address and profile picture, telephone
          number) that you provide, location data and visit data.
        </p>
        <p>
          If you log in to OHM via an account of a social network account (such as Facebook or
          Gmail), you grant permission to collect your data via that account, such as your e-mail
          address, name and other data that you want to provide to us.
        </p>
        <p>
          We use your data for the following purposes:
          <ul>
            <li>providing and invoicing our services and informing them about their progress;</li>
            <li>analysis, product development and marketing</li>
            <li>
              informing about our products and services; if you do not appreciate this information,
              you can indicate this on your personal settings page or you can contact our customer
              administration (info@himrkt.com);
            </li>
            <li>
              provision of data to third parties on the basis of legal obligations (for example in
              the event of fraud or abuse).
            </li>
          </ul>
        </p>
        <p>
          If we engage third parties to also provide the services, we and the third parties
          concerned guarantee the confidentiality and security of the personal data.
        </p>
        <p>
          Finally, we record information (including the IP address used) for the purpose of
          compiling usage statistics, as well as for the security of our app.
        </p>

        <h2>Confidentiality and secrecy</h2>
        <p>
          Your personal data is in good hands with us. Care is important for us! Our services are
          secured, the data is regularly backed up to be safe and we take the confidentiality of
          your personal data very seriously.
        </p>

        <h2>Company transfer</h2>
        <p>
          With the further growth and development of OHM, it may happen that one or more parts or
          assets of the company are transferred to a third party. In that case, the data of our
          customers will also be transferred.
        </p>

        <h2>Transfer to countries outside the EU</h2>
        <p>
          For technical and operational reasons, it may be necessary for your data to be transferred
          (to servers of) affiliated companies outside of Europe, where privacy protection
          regulations may not offer the same protection as in the European Union. However, we will
          always take appropriate measures to ensure that your data is protected as well as
          possible.
        </p>

        <h2>Knowledge and improvement of your data</h2>
        <p>
          You can always view your own data and your personal settings and change them if necessary.
          If you want to know what data we have recorded about you or if you want to change your
          data, you can contact our customer service via info@tastyclub.nl.
        </p>

        <h2>About the Privacy Policy</h2>
        <p>
          This Privacy Policy is subject to change. These changes will be announced via the OHM
          Website.
        </p>
        <p>
          This Privacy Policy is limited to the Website of OHM and does not apply to linked
          third-party websites.
        </p>

        <h2>Questions?</h2>
        <p>
          If you have any questions about this Privacy Policy, you can send an email to our customer
          service at support@himrkt.com.
        </p>
      </div>
    </Layout>
  )
}
