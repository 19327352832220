import { AxiosInstance } from 'axios'

import { EndpointType } from 'services/api/types'

enum URL {
  CREATE_MEETING = 'api/cabinet/zoom/meet',
}

const CREATE_MEETING: EndpointType = (data: any, fetcher: AxiosInstance, headers) => {
  return fetcher.request({
    method: 'POST',
    url: URL.CREATE_MEETING,
    headers,
    data,
  })
}

export const ZOOM_API = {
  CREATE_MEETING,
}
