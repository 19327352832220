import { useRef, useEffect } from 'react'

type Props = { onOutsideClick: () => void } & React.HTMLProps<HTMLDivElement>

function OutsideClickHandler(props: Props) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.onOutsideClick()
    }
  }
  const { children, ...rest } = props

  return (
    <div {...rest} ref={ref}>
      {children}
    </div>
  )
}

export { OutsideClickHandler }
