export const MainPageLocales = {
  hero: {
    title: 'Удивительное рядом',
    description: 'Лучшие туры по историческим местам',
  },
  comfort: {
    title: 'Тип отдыха',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
  destination: {
    title: 'Открытые направления',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
  recommendation: {
    title: 'Топ предложения',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
  subscription: {
    title: 'Подпишитесь на рассылку',
    description: 'Будьте в курсе последних акций и скидок',
    subscribe: 'Подписаться',
    email: 'Эл. почта',
  },
};
