import { observable, action, makeAutoObservable, computed, flow } from 'mobx'

import { RootStore } from 'stores/root-store'
import { makeResource, Resource } from 'utils/make-resource'
import { api } from 'services/api'

export class ProjectStore {
  @observable
  tasksResource: Resource<any, any>

  @observable
  tasks: any

  @observable
  task: any

  @observable
  projects: Resource<any, any>

  @observable
  currentProjectId: any

  // @observable
  // currentTask: any

  @observable
  currentTaskId: any

  @observable
  taskResource: Resource<any, any>

  @computed
  get currentTask() {
    if (!this.tasks) {
      return undefined
    }

    const tasks = [...this.tasks.companion_tasks, ...this.tasks.my_tasks]
    return tasks.filter((task) => task.id === Number(this.currentTaskId))?.[0] || undefined
  }

  constructor(private rootStore: RootStore) {
    this.createResources()
    makeAutoObservable(this)
    this.loadTasks.bind(this)
  }

  @action
  async loadProjects(payload) {
    const raw: any = await api.call('GET_PROJECTS', payload)

    if (raw.success) {
      return raw
    }

    throw new Error(raw.message)
  }

  @action
  setCurrentProject(id) {
    this.currentProjectId = id
  }

  @action
  setCurrentTask(id) {
    this.currentTaskId = id || undefined
  }

  loadTasks = flow(function* loadTasks(this, payload: any) {
    const raw: any = yield api.call('GET_TASKS', payload)

    if (raw.success) {
      this.tasks = { ...raw.data }
      return raw.data
    }

    throw new Error(raw.message)
  })

  @action
  async loadTask(payload) {
    const raw: any = await api.call('GET_TASK', payload)

    if (raw.success) {
      return raw.data
    }

    throw new Error(raw.message)
  }

  @action
  async updateTask(payload: any) {
    const raw: any = await api.call('UPDATE_TASK', payload)

    if (raw.success) {
      this.task = raw.data
    } else {
      throw new Error(raw.message)
    }
  }

  @action
  async deleteAttachment(payload: any) {
    const raw: any = await api.call('DELETE_ATTACHMENT', payload)

    if (raw.success) {
      this.task = { ...this.task, task_attachments: raw.task_attachments }
      return
    }

    throw new Error(raw.message)
  }

  @action
  async uploadAttachment(payload) {
    const raw: any = await api.call('UPLOAD_ATTACHMENTS', payload)

    if (raw.success) {
      this.task = { ...this.task, task_attachments: raw.task_attachments }
      return
    }

    throw new Error(raw.message)
  }

  @action
  async createComment(payload: any) {
    const raw: any = await api.call('CREATE_COMMENT', payload)

    if (raw.success) {
      this.task = { ...this.task, comments: [...this.task.comments, raw.comment] }
      return raw
    }

    throw new Error(raw.message)
  }

  createResources(): void {
    this.projects = makeResource(async (params) => {
      const raw = await this.loadProjects(params)
      if (raw.success) {
        this.setCurrentProject(raw.data[0].id)
        return raw.data
      }
    })

    this.tasksResource = makeResource(async (id) => await this.loadTasks(id))

    this.taskResource = makeResource(
      async () =>
        (this.task = await this.loadTask({
          projectId: this.currentProjectId,
          taskId: this.currentTaskId,
        })),
    )
  }
}
