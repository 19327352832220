import { useContext, useEffect } from 'react'
import classNames from 'classnames/bind'
import { Select } from 'ui/select'
import { useHistory, useParams } from 'react-router-dom'
import styles from './task-select.module.scss'
import { Access } from 'common/types'

const cn = classNames.bind(styles)
type Props = React.HTMLProps<HTMLDivElement> & any

function TaskSelect(props: Props) {
  const { currentProjectId, projects, aviableTasks, currentUser } = props
  const history = useHistory()
  const { companyId } = useParams()
  const handleProjectChange = (selectedProject) => {
    if (currentUser.current.role === Access.CUSTOMERDASHBOARD) {
      history.push(`/tasks?projectId=${selectedProject.id}`)
    }
    if (currentUser.current.role === Access.COUNTRY_MANAGER) {
      history.push(`/clients/${companyId}?projectId=${selectedProject.id}`)
    }
  }

  useEffect(() => {
    aviableTasks.reset()
  }, [currentProjectId])
  return (
    <Select
      value={projects.find((project) => project.id === Number(currentProjectId))}
      getOptionLabel={(option) => option.country.en_name}
      getOptionValue={(option) => option.id}
      handleChange={handleProjectChange}
      options={projects}
    />
  )
}

export { TaskSelect }
