import React from 'react'
import { Field } from 'react-final-form'

import { FormField, FormFieldProps } from '../form-field'

function FinalFormField(props: Omit<FormFieldProps & { error?: any }, 'meta'>) {
  const { name } = props

  return (
    <Field name={name}>
      {({ input, meta }) => <FormField {...input} {...props} meta={meta} />}
    </Field>
  )
}

export { FinalFormField }
