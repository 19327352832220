import { EndpointType } from 'services/api/types'

enum URL {
  FEEDBACK = 'feedback',
}

const FEEDBACK: EndpointType = (data: { email: string; name?: string }, fetcher, headers) =>
  fetcher.request<any>({
    method: 'post',
    url: URL.FEEDBACK,
    data,
  })

export const STATIC_PAGES_API = {
  FEEDBACK,
}
