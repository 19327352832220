import { makeAutoObservable } from 'mobx'

import { makeResource } from 'utils/make-resource'

import { RootStore } from './root-store'

export class BootstrapResources {
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this)
  }

  criticalAppDependencies = makeResource(async () => {
    try {
      const [currentUser] = await Promise.all([this.rootStore.currentUser.fetch()])
      // this.rootStore.notifications.init(currentUser.id)
      if (currentUser.id !== 9) {
        const country: any = Object.values(currentUser.research_available_countries)[0]
        const country_id = country.country_id

        await Promise.resolve(this.rootStore.research.currentCountry(country_id))
      }

      return { currentUser }
    } catch (e: any) {
      if (e.response.data.code === 1013002) {
        window.location.href = '/registration/company'
      }

      if (e.response.data.code === 1013003) {
        window.location.href = '/registration/countries'
      }
    }

    return { currentUser: null }
  })
}
