import React from 'react'
import classNames from 'classnames/bind'
import { Form } from 'react-final-form'

import { FinalFormField } from 'ui/final-form-field'
import { Button } from 'ui/button'
import { FormError } from 'ui/form-error'

import styles from './promocode-form.module.scss'

const cn = classNames.bind(styles)
type Props = React.HTMLProps<HTMLDivElement>
function PromocodeForm(props: any) {
  const { coupon, handleSubmit, error, setError, processing, buttonTitle } = props

  return (
    <Form
      initialValues={coupon}
      onSubmit={handleSubmit}
      render={({ hasSubmitErrors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {error && <FormError message={error} close={() => setError(null)} />}

          <FinalFormField
            disabled={processing}
            name={'coupon'}
            type={'coupon'}
            label="Coupon"
            placeholder="Coupon"
            id="promocode"
            value={coupon}
          />

          <Button className={cn('button')} type="submit" disabled={processing}>
            {buttonTitle}
          </Button>
        </form>
      )}
    ></Form>
  )
}

export { PromocodeForm }
