import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { useToggle } from 'utils/useToggle'
import { Condition } from 'ui/condition'
import { OutsideClickHandler } from 'ui/outside-click-handler'

import styles from './header.module.scss'

const cn = classNames.bind(styles)

type Props = React.HTMLProps<HTMLDivElement>

const HeaderMobile = (props: Props): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useToggle()
  const history = useHistory()

  return (
    <header className={cn('header')}>
      <div className={cn('header-wrapper')}>
        <div className={cn('nav')}>
          <div
            className={cn('nav-item', '_logo', { _purple: isMenuVisible })}
            onClick={() => window.open('https://himrkt.com')}
          />

          <div
            className={cn('nav-item', '_burger', { _visible: isMenuVisible })}
            onClick={() => setIsMenuVisible(true)}
          >
            <div />
            <div />
            <div />
          </div>
        </div>
        <Condition match={isMenuVisible}>
          <OutsideClickHandler onOutsideClick={setIsMenuVisible}>
            <div className={cn('menu')}>
              <div
                onClick={() => history.push('/#services')}
                className={cn('menu-item', '_services')}
              >
                Our services
              </div>
              <div onClick={() => history.push('/#benefits')} className={cn('menu-item')}>
                Your benefits
              </div>
              <div onClick={() => history.push('/country-manager')} className={cn('menu-item')}>
                Country manager
              </div>
              <div onClick={() => history.push('/auth')} className={cn('menu-item')}>
                Login
              </div>
              <div
                onClick={() => history.push('/registration/identity')}
                className={cn('menu-item', '_login')}
              >
                Get started
              </div>
            </div>
          </OutsideClickHandler>
        </Condition>
      </div>
    </header>
  )
}

export { HeaderMobile }
