import { AxiosInstance } from 'axios'

import { EndpointType } from 'services/api/types'

enum URL {
  UPDATE_PROFILE = 'api/cabinet/user',
  UPDATE_PAYMENT_METHOD = 'api/cabinet/stripe/update-default-payment-method',
  DELETE_PAYMENT_METHOD = 'api/cabinet/stripe/delete-payment-method',
  CREATE_PAYMENT_METHOD = 'api/cabinet/stripe/payment',
}

const UPDATE_PROFILE: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'post',
    url: URL.UPDATE_PROFILE,
    data,
    headers,
  })

const UPDATE_PAYMENT_METHOD: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'post',
    url: URL.UPDATE_PAYMENT_METHOD,
    data,
    headers,
  })

const CREATE_PAYMENT_METHOD: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'post',
    url: URL.CREATE_PAYMENT_METHOD,
    data,
    headers,
  })

const DELETE_PAYMENT_METHOD: EndpointType<any, any> = (data, fetcher, headers) =>
  fetcher.request({
    method: 'post',
    url: URL.DELETE_PAYMENT_METHOD,
    data: {
      ...data,
      _method: 'DELETE',
    },
    headers,
  })

export const SETTINGS_API = {
  UPDATE_PROFILE,
  UPDATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
}
