import { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames/bind'
import { useLocation, useHistory } from 'react-router-dom'
import { AppContext } from 'app-context'
import { PaymentForm } from 'features/payment-form/components/payment-form'
import { PromocodeForm } from 'features/promocode-form/components/promocode-form'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import { Text, TextType } from 'ui/text'
import styles from './checkout.module.scss'

const cn = classnames.bind(styles)

const CheckoutFormContainer = () => {
  const stripe: any = useStripe()
  const elements: any = useElements()
  const [customer, setCustomer]: any = useState({})
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [error, setError]: any = useState(null)
  const [errorPromocode, setErrorPromocode]: any = useState(null)
  const [discount, setDiscount] = useState(0)

  const [billingDetails, setBillingDetails] = useState({
    name: '',
  })
  const [coupon, setCoupon]: any = useState(null)
  const { checkout } = useContext(AppContext)
  const {
    state: { selectedProducts },
  } = useLocation()

  const history = useHistory()

  useEffect(() => {
    checkout.getCustomer().then((res) => setCustomer(res))
  }, [])

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (cardComplete) {
      setProcessing(true)
      elements.getElement(CardNumberElement).update({ disabled: true })
      elements.getElement(CardExpiryElement).update({ disabled: true })
      elements.getElement(CardCvcElement).update({ disabled: true })
    }

    const payload = await stripe.confirmCardSetup(customer.client_secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement, { hidePostalCode: true }),
        billing_details: event,
      },
    })

    if (payload.error) {
      setError(payload.error)
      setProcessing(false)
      elements.getElement(CardNumberElement).update({ disabled: false })
      elements.getElement(CardExpiryElement).update({ disabled: false })
      elements.getElement(CardCvcElement).update({ disabled: false })
    } else {
      createSubscription({
        paymentMethodId: payload.setupIntent.payment_method,
        productId: selectedProducts[0].product_id,
        planIds: selectedProducts.map((product) => product.plan_id),
      })
    }
  }

  const createSubscription = ({ paymentMethodId, planIds, productId }) => {
    checkout
      .subscribe({
        paymentMethodId,
        product_id: productId,
        plan_ids: planIds,
        coupon: coupon,
      })
      .then((result) => {
        if (result.code === 1039003) {
          history.push({
            pathname: '/registration/payment-error',
            state: result.data,
          })
        }
        if (result.success) {
          const timer = setTimeout(() => {
            history.push('/research')
          }, 5000)
          return () => clearTimeout(timer)
        } else {
          stripe
            .confirmCardPayment(result.data.payment.client_secret, {
              payment_method: result.data.payment.payment_method,
            })
            .then((result) => {
              if (result.paymentIntent.status === 'succeeded') {
                const timer = setTimeout(() => {
                  history.push('/research')
                }, 5000)
                return () => clearTimeout(timer)
              }
            })
        }
      })
  }

  const getTotalPrice = () => {
    if (discount > 0) {
      return (
        selectedProducts
          .map((product) => product.country.price)
          .reduce((acc, product) => {
            return Number(acc) + Number(product)
          }, 0) -
        (discount / 100) *
          selectedProducts
            .map((product) => product.country.price)
            .reduce((acc, product) => {
              return Number(acc) + Number(product)
            }, 0)
      ).toFixed(2)
    } else {
      return selectedProducts
        .map((product) => product.country.price)
        .reduce((acc, product) => {
          return Number(acc) + Number(product)
        }, 0)
    }
  }

  const handleCheckPromocode = async (event) => {
    setProcessing(true)

    let coupon = (document.getElementById('promocode') as HTMLInputElement).value

    checkout
      .checkPromocode({
        coupon: coupon,
      })
      .then((result) => {
        if (result.success) {
          const timer = setTimeout(() => {
            setDiscount(result.data.discount)
            setCoupon(coupon)
            setErrorPromocode(null)
            setProcessing(false)
          }, 1000)
          return () => clearTimeout(timer)
        } else {
          setProcessing(false)
          setErrorPromocode(result.data)
        }
      })
  }

  return (
    <>
      <div className={cn('auth__logo')} onClick={() => window.open('https://himrkt.com')} />
      <div className={cn('wrapper')}>
        <div className={cn('form')}>
          <Text className={cn('step')} type={TextType.TextSmall}>
            3 step to 4
          </Text>
          <PaymentForm
            billingDetails={billingDetails}
            handleSubmit={handleSubmit}
            error={error}
            setError={setError}
            processing={processing}
            CardNumberElement={CardNumberElement}
            setCardComplete={setCardComplete}
            CardCvcElement={CardCvcElement}
            CardExpiryElement={CardExpiryElement}
            buttonTitle={processing ? 'Processing...' : `Pay $${getTotalPrice()}`}
          />
        </div>
        <div className={cn('sidebar')}>
          <div className={cn('sidebar-wrapper')}>
            <div className={cn('sidebar-block')}>
              <div className={cn('sidebar-items')}>
                {/* <div className={cn('sidebar-item')}>
                  <Text className={cn('step')} type={TextType.TextSmall}>
                    Due after <br /> 14 day trial
                  </Text>
                </div> */}
                <div className={cn('sidebar-item')}>
                  <Text type={TextType.HeaderSmall} className={cn('total')}>
                    ${getTotalPrice()}
                  </Text>
                </div>
              </div>
              <hr className={cn('sidebar-border')} />

              <div className={cn('sidebar-items', '_trial')}>
                <PromocodeForm
                  coupon={coupon}
                  handleSubmit={handleCheckPromocode}
                  error={errorPromocode}
                  setError={setErrorPromocode}
                  processing={processing}
                  buttonTitle={processing ? 'Processing...' : `Check promocode`}
                />
                {/* <div className={cn('sidebar-item')}>
                  <Text className={cn('step')} type={TextType.TextSmall}>
                    Due today
                  </Text>
                </div> */}
                {/* <div className={cn('sidebar-item')}>
                  <Text type={TextType.HeaderSmall} className={cn('trial-title')}>
                    free 14 day trial
                  </Text>
                </div> */}
              </div>
            </div>

            <Text type={TextType.TextSmall} className={cn('sidebar-desc')}>
              I agree to the{' '}
              <a
                className={cn('link')}
                target="_blank"
                rel="noreferrer"
                href="https://himrkt.com/privacy-policy"
              >
                Privacy Policy
              </a>{' '}
              and am familiar with the{' '}
              <a
                className={cn('link')}
                target="_blank"
                rel="noreferrer"
                href="https://himrkt.com/terms-of-service"
              >
                Terms of Service
              </a>
            </Text>
          </div>
        </div>
      </div>
    </>
  )
}

const ObservedCheckoutFormContainer = observer(CheckoutFormContainer)

export { ObservedCheckoutFormContainer as CheckoutForm }
