import React, { useCallback, useState, useContext, useEffect } from 'react'
import classNames from 'classnames/bind'
import { AppContext } from 'app-context'
import { Text, TextType } from 'ui/text'
import { Button } from 'ui/button'
import styles from './charts.module.scss'
import { Spinner } from 'ui/spinner'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './charts.css'
import { toJS } from 'mobx'
import { setConstantValue } from 'typescript'

const cn = classNames.bind(styles)

function Graphs(data) {
  const store = useContext(AppContext)
  // console.log(graphs)
  let graphs = data.graphs
  graphs.data = store.research.graphs
  if (Object.keys(graphs).length == 0) {
    return null
  }

  function setOptions(graph, flag) {
    const keys: any = Object.keys(graph)
    let values: any = Object.values(graph)
    values = values.map((i) => Math.round(Number(i) * 10) / 10)

    const color = flag ? '#00DF31' : '#FF4433'
    const gradient_0 = flag ? '#00DF3133' : '#FF443333'
    const gradient_1 = flag ? '#00DF3100' : '#FF443300'
    const options = {
      title: '',
      chart: {
        type: 'area',
        height: 151,
        width: 764,
      },
      tooltip: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        visible: false,
        labels: {
          enabled: false,
        },
      },

      xAxis: {
        categories: keys,
        min: 1,
        max: keys.length - 1.35,
        padding: '20px',
      },

      legend: {
        enabled: false,
      },

      plotOptions: {
        series: {
          color: `${color}`,
          enableMouseTracking: false,
          dataLabels: {
            enabled: true,
            color: '#000000',
            verticalAlign: 'left',
            y: -1000,
            className: 'data-labels',
            align: 'left',
          },
        },
        area: {
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, `${gradient_0}`],
              [1, `${gradient_1}`],
            ],
          },
          lineWidth: 3,
          marker: {
            enabled: false,
          },
          shadow: false,
          threshold: null,
        },
      },

      series: [
        {
          data: values,
        },
      ],
    }
    return options
  }

  return (
    <div className={cn('charts-graphs')}>
      <div className={cn('graphs-country')}>
        <img
          src={graphs.flag !== undefined ? graphs.flag : graphs.data.flag}
          className={cn('graphs-flag')}
        ></img>
        <div className={cn('graphs-country-name')}>
          {graphs.country_name !== undefined ? graphs.country_name : graphs.data.country_name}
        </div>
      </div>
      <div className={cn('graphs-title')}>
        {graphs.title !== undefined ? graphs.title : graphs.data.title}
      </div>
      {graphs.data.data.data.map((graph: any, index: any) => (
        <Text key={Math.random()}>
          <Text key={Math.random()} className={cn('graphs-subtitle')}>
            {graph.name}
          </Text>
          <HighchartsReact
            highcharts={Highcharts}
            options={setOptions(graph.graph, index % 2 == 0)}
          />
        </Text>
      ))}
    </div>
  )
}
export { Graphs }
