import React from 'react'
import classNames from 'classnames/bind'

import { Access } from '../../../../common/types'
import { ReactComponent as ZoomLogo } from '../../../../assets/icons/zoom.svg'

import styles from './zoom-button.module.scss'
import { Spinner } from '../../../../ui/spinner'

const cn = classNames.bind(styles)

type Props = {
  role: any
  onClick: any
}

enum ButtonState {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
}

function ZoomButton({ role, onClick }: Props) {
  const [buttonState, setButtonState] = React.useState<ButtonState>(ButtonState.IDLE)
  const onClickHandler = React.useCallback(async () => {
    try {
      setButtonState(ButtonState.PENDING)

      onClick()
    } catch (e) {
      console.log(e)
    } finally {
      setButtonState(ButtonState.SUCCESS)

      setTimeout(() => {
        setButtonState(ButtonState.IDLE)
      }, 3000)
    }
  }, [onClick])

  if (role !== Access.COUNTRY_MANAGER) {
    return null
  }

  return (
    <button
      className={cn('button', { _success: buttonState === ButtonState.SUCCESS })}
      disabled={buttonState === ButtonState.PENDING}
      onClick={onClickHandler}
    >
      {buttonState === ButtonState.IDLE && <ZoomLogo />}
      {buttonState === ButtonState.PENDING && <Spinner height={64} className={cn('spinner')} />}
      {buttonState === ButtonState.SUCCESS && 'Zoom link was created'}
    </button>
  )
}

export { ZoomButton }
