import { flow, observable, action } from 'mobx'

import { RootStore } from 'stores/root-store'
import { api } from 'services/api'
import { makeResource, Resource } from 'utils/make-resource'

export class ClientsStore {
  @observable
  availableClients: Resource<any>

  constructor(private rootStore: RootStore) {
    this.createResources()
  }

  @action
  async loadAvailableClients() {
    const raw: any = await api.call('AVAILABLE_CLIENTS')

    if (raw.success) {
      return raw.data
    }

    throw new Error(raw.message)
  }

  createResources(): void {
    this.availableClients = makeResource<any, []>(async () => await this.loadAvailableClients())
  }
}
