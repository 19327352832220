import { observable, flow, makeAutoObservable } from 'mobx'

import { api, AsyncState } from 'services/api'

import { RootStore } from './root-store'

export class CurrentUser {
  @observable
  dataState = AsyncState.IDLE

  @observable
  id: string

  @observable
  current: any

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this)
  }

  fetch = flow(async function* (this: CurrentUser) {
    this.current = yield api.call('GET_USER')
    return this.current
  })

  async createZoomMeeting(projectId) {
    return await api.call('CREATE_MEETING', { project_id: projectId })
  }
}
