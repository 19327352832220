import React from 'react'
import classNames from 'classnames/bind'

import { Text, TextType } from 'ui/text'
import { Footer } from 'components/footer'

import { ReactComponent as Logo } from '../../assets/custom-report.svg'
import { ReactComponent as MiroLogo } from '../../assets/miro-logo.svg'
import { ReactComponent as NotionLogo } from '../../assets/notion-logo.svg'
import { ReactComponent as ArrivalLogo } from '../../assets/arrival-logo.svg'
import { ReactComponent as Checkmark } from '../../assets/checkmark.svg'
import { ReactComponent as TCLogo } from '../../assets/tc.svg'
import { ReactComponent as VergeLogo } from '../../assets/the-verge.svg'
import { ReactComponent as TNWLogo } from '../../assets/tnw.svg'
import { ReactComponent as WiredLogo } from '../../assets/wired.svg'
import { ReactComponent as CostEffective } from '../../assets/cost-effective.svg'
import { ReactComponent as ExperiencedTeam } from '../../assets/expirienced-team.svg'
import { ReactComponent as Technology } from '../../assets/technology.svg'
import { ReactComponent as How1 } from '../../assets/how-1.svg'
import { ReactComponent as How2 } from '../../assets/how-2.svg'
import { ReactComponent as How3 } from '../../assets/how-3.svg'
import { ReactComponent as HowBGTop } from '../../assets/how-bg-top.svg'
import { ReactComponent as HowBGBottom } from '../../assets/how-bg-bottom.svg'
import { WhatClientsSay } from '../../components/what-clients-say/what-clients-say'
import { WhatInside } from '../../components/what-inside/what-inside'

import { useVisibleState } from 'utils/useVisibleState'
import { AppContext } from 'app-context'
import { Checkout } from 'features/checkout/pages/checkout'

import styles from './custom-report.module.scss'

const cn = classNames.bind(styles)

export function CustomReport() {
  const topChild = React.useMemo(() => {
    return (
      <div className={cn('buy')}>
        <Text type={TextType.HeaderXLarge} className={cn('buy__title')}>
          Get Your Report
        </Text>
        <div className={cn('buy__container')}>
          <div className={cn('buy__description')}>
            <Logo />
            <div className={cn('buy__prices')}>
              <div className={cn('buy__prices-current')}>
                <Text type={TextType.TextLarge}>Price</Text>
                <Text type={TextType.HeaderXXLarge}>99$</Text>
              </div>
              <div className={cn('buy__prices-period')}>
                <Text type={TextType.TextLarge}>Time</Text>
                <Text type={TextType.HeaderXXLarge}>1-3 days</Text>
              </div>
            </div>
          </div>
          <form className={cn('buy__form')}>
            <input
              required={true}
              type={'email'}
              className={cn('buy__form-field')}
              placeholder={'Your Email'}
            />
            <button type={'submit'} className={cn('buy__form-button')}>
              <Text type={TextType.HeaderMedium}>Buy Report</Text>
            </button>
          </form>
        </div>
      </div>
    )
  }, [])

  return (
    <div className={cn('wrapper')}>
      <div className={cn('header')}>
        <Logo className={cn('header__logo')} />
      </div>
      <div className={cn('offer')}>
        <Text type={TextType.HeaderXXLarge} className={cn('offer__title')}>
          International Market Research Reports tailored for you
        </Text>
        <Text type={TextType.TextXLarge} className={cn('offer__description')}>
          Our team of industry-specific research specialists offer expert, unbiased insights on
          which reports will best meet your needs.
        </Text>
        <form className={cn('offer__form')}>
          <div className={cn('offer__form-container')}>
            <input
              required={true}
              className={cn('offer__form-field')}
              type={'email'}
              placeholder={'Your Email'}
            />
            <button className={cn('offer__form-button')}>
              <Text type={TextType.HeaderMedium}>Get started</Text>
            </button>
          </div>
        </form>
        <div className={cn('teammates')}>
          <Text type={TextType.TextXXLarge} className={cn('teammates__header')}>
            You&apos;re in good company
          </Text>
          <div className={cn('teammates__row')}>
            <MiroLogo />
            <NotionLogo />
            <ArrivalLogo />
          </div>
        </div>
      </div>
      <div className={cn('how')}>
        <HowBGTop />
        <div className={cn('how__container')}>
          <Text type={TextType.HeaderLarge} className={cn('how__title')}>
            How it works
          </Text>
          <How1 className={cn('how__customize-image')} />
          <Text type={TextType.TextXXLarge} className={cn('how__customize')}>
            Customise your research requests: choose target market, specify your industry and add
            details on your goals
          </Text>

          <How2 className={cn('how__ai-image')} />
          <Text type={TextType.TextXXLarge} className={cn('how__ai')}>
            We apply all possible methods to gather required data for your market research from data
            mining till our AI technology
          </Text>

          <How3 className={cn('how__time-image')} />
          <Text type={TextType.HeaderMedium} className={cn('how__time')}>
            Up to 3 work days you get a complete market research report in design and easy to read
            PDF
          </Text>
        </div>
        <HowBGBottom />
      </div>
      <WhatInside
        header={
          <>
            <Text type={TextType.HeaderLarge} className={cn('what-inside__title')}>
              What Report contains
            </Text>
            <Text type={TextType.HeaderMedium} className={cn('what-inside__description')}>
              You get comprehensive report of market state in beautifully designed, easy to
              read&nbsp;PDF
            </Text>
          </>
        }
        className={cn('what-inside')}
      />
      <div className={cn('press')}>
        <Text type={TextType.TextXXLarge} className={cn('press__header')}>
          Press about us
        </Text>
        <div className={cn('press__row')}>
          <TCLogo />
          <VergeLogo />
          <TNWLogo />
          <WiredLogo />
        </div>
      </div>
      <div className={cn('markets')}>
        <Text type={TextType.HeaderLarge} className={cn('markets__title')}>
          What makes us so great
        </Text>
        <div className={cn('markets__row')}>
          <div className={cn('markets__item')}>
            <div className={cn('markets__item-image')}>
              <CostEffective />
            </div>
            <Text type={TextType.TextXLargeMedium}>Cost Effective</Text>
            <Text type={TextType.TextLarge} className={cn('markets__item-text')}>
              We offer affordable price for our service to complete research assignments while
              taking into account how overwhelming and extremely expensive that could be for
              companies.
            </Text>
          </div>
          <div className={cn('markets__item')}>
            <div className={cn('markets__item-image')}>
              <ExperiencedTeam />
            </div>
            <Text type={TextType.TextXLargeMedium}>Experienced Team</Text>
            <Text type={TextType.TextLarge} className={cn('markets__item-text')}>
              We are the experienced international team, who completed hundreds of researches across
              various markets and industries.
            </Text>
          </div>
          <div className={cn('markets__item')}>
            <div className={cn('markets__item-image')}>
              <Technology />
            </div>
            <Text type={TextType.TextXLargeMedium}>Technology</Text>
            <Text type={TextType.TextLarge} className={cn('markets__item-text')}>
              Mixing our vast experience, methodologies and custom developed software we reached the
              high efficiency to work on research assignment and come out with results meeting
              requirements of our clients.
            </Text>
          </div>
        </div>
      </div>

      <div className={cn('clients')}>
        <WhatClientsSay />
      </div>

      <Footer topChild={topChild} />
    </div>
  )
}
